import MainPage from "../../reusable/MainPage";
import NotActive from "../Both/NotActive";
import Marquee from "react-fast-marquee";

var Welcome = (props) => {
    if (!props.active) return <NotActive />
    return (
        <MainPage title="welcome" className="welcome" active={props.active}>
            <div>
                Welcome to <span className="sc">regimensocial</span>.
                <Marquee
                    gradient={false}
                    style={{
                        fontSize: "25pt"
                    }}
                    
                    direction="right"
                    speed={120}
                >
                    <b>YOU WILL ASCEND</b>ASCENSIONREGIMEN
                </Marquee>
                <Marquee
                    gradient={false}
                    style={{
                        fontSize: "25pt"
                    }}
                    speed={120}
                    direction="left"
                >
                    <b>YOU WILL ASCEND</b>ASCENSIONREGIMEN 
                </Marquee>
                <Marquee
                    gradient={false}
                    style={{
                        fontSize: "25pt"
                    }}
                    
                    direction="right"
                    speed={120}
                >
                    ASCENSIONREGIMEN<b>YOU WILL ASCEND</b>
                </Marquee>
            </div>
        </MainPage>
    )
}


export default Welcome;