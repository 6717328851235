import Button from "./Button";

import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";

const SpecialBar = (props) => {
    

    // var bottomStyle = (props.menuMobileHeight && props.bottom ) ? {
    //     marginBottom: props.menuMobileHeight,
    //     // transition: "margin 300ms",
    //     // transitionDelay: "300ms"
    // } : {}

    var bottomStyle = {

    }
    
    var hubContent = (props.content || []).map((hub, i) => {
        var first = (
            <Button className={`first ${!(hub.first && hub.first.title) ? "invalid" : ""} ${(hub.first && hub.first.active) ? "active" : "inactive"} ${props.noTransition ? "noTransition" : ""}`} noAMeasure={1} additionalSpacingY={30} noRound={1}
            dull = {((hub.first) ? hub.first.dull : true)} 
                onClick = {(hub.first && hub.first.func) || null}
                disabled = {(props.top && props.hiddenUI ? true : false) || !props.active}
            >
                {(hub.first && hub.first.title) || ""}
            </Button>
        );

        var last = (
            <Button className={`last ${!(hub.last && hub.last.title) ? "invalid" : ""} ${(hub.last && hub.last.active) ? "active" : "inactive"} ${props.noTransition ? "noTransition" : ""}`} noAMeasure={1} additionalSpacingY={30} noRound={1}
                dull = {(hub.last) ? hub.last.dull : true} 
                onClick = {(hub.last && hub.last.func) || null}
                disabled = {(props.top && props.hiddenUI ? true : false) || !props.active}
            >
                {(hub.last && hub.last.title) || ""}
            </Button>
        );

        return (
            <div key={"sbh-" + i} className={`specialBarHub ${(props.active && hub.active) ? "active" : "inactive"} ${props.bottom ? "bottom" : "top"} 
            ${props.bottom && !props.hiddenUI ? "hiddenUI" : ""}
            ${props.top && props.hiddenUI ? "hiddenUI" : ""}
            ${props.noTransition ? "noTransition" : ""}
            `} style={bottomStyle}>
                {first}
                {/* <div className="centre" onClick={props.centreFunction || null}></div> */}
                {last}
            </div>
        )
    })

    var placeholder;

    if (!props.content.length) {
        placeholder = (
            <div className={`specialBarHub inactive ${props.bottom ? "bottom" : "top"} 
                ${props.bottom && !props.hiddenUI ? "hiddenUI" : ""} 
                
                ${props.top && props.hiddenUI ? "hiddenUI" : ""}
                
                ${props.noTransition ? "noTransition" : ""}

            `} style={bottomStyle}>
            

                {/* <div className="centre" onClick={props.centreFunction || null}></div> */}

            </div>
        )
    }

    // console.log(props.menuMobileHeight)

    return (
        <div className={`specialBar perfAll ${!props.hiddenUI ? "min" : "normal"}  ${props.active ? "active" : "inactive"} ${props.bottom ? "bottom" : "top"} ${props.noTransition ? "noTransition" : ""} `} onClick={props.onClick || null}>
            {props.content.length ? hubContent : placeholder}
        </div>
    )

}

const MainPage = (props) => {
    
    const context = useContext(UserContext)

    var hiddenUI = context.hiddenUI;

    // console.log(props)

    return (
        <>
            <SpecialBar top={true} noTransition={props.noTransition} active={props.active || false} 
            
            // onClick={hiddenUI ? () => context.updateState("hiddenUI", !context.hiddenUI) : null} 
            
            content={props.specialBar || []} centreFunction={!hiddenUI ? () => context.updateState("hiddenUI", !context.hiddenUI) : null} hiddenUI = {hiddenUI} />
                
            <div className={`titleBar ${hiddenUI ? "hidden" : ""}`} >
                <div className={`title ${props.tClassName || ""}`} >
                    
                    {props.title}
                    
                </div>
                <div className="division">
                </div>
            </div>
            
            <div  className={`pageContainer ${props.className || props.title.replaceAll(" ", "") || ""}`}>
                {props.children}
            </div>

            <SpecialBar bottom={true} noTransition={props.noTransition} active={props.active || false} content={props.specialBar || []} hiddenUI = {hiddenUI} />
        </>
    )
}

export default MainPage;