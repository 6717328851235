import React, { createContext, Component } from "react";
import regimen from "../rs";
import { connection, initSignalR } from "../main.js"

export const UserContext = createContext();

class UserContextProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: "connecting",
            address: regimen.getAddress(),
            hiddenUI: false,
            errorInfoResponse: null,
            production: regimen.isProduction(),
            userAccount: {},
            initSockets: false,
            friends: {},
            inboundFriends: {},
            outboundFriends: {}
        }

        this.updateState = this.updateState.bind(this);
        this.attemptConnection = this.attemptConnection.bind(this);
        this.handleWebSocket = this.handleWebSocket.bind(this);
    }

    handleWebSocket(name, data) {
        switch (name) {
            case "profileUpdate":
                this.setState({
                    userAccount: data
                })
                break;
            case "friendRequest":
                this.getFriends();
                break;
            case "friendInfoUpdate":
                var newFriends = this.state.friends;
                newFriends[newFriends.findIndex(e => e.userUsername === data.name)].friend = data;
                this.setState({
                    friends: newFriends
                });
                break;
        
            default:
                break;
        }
    }

    getFriends() {
        var self = this;

        regimen.retrieveFriends("")
        .then((e) => {

            self.setState({
                friends: e.data
            });
        })

        
        regimen.retrieveFriends("requests")
        .then((e) => {

            self.setState({
                inboundFriends: e.data
            });
        })

        regimen.retrieveFriends("myRequests")
        .then((e) => {

            self.setState({
                outboundFriends: e.data
            });
        })
    }

    attemptConnection() {
        this.setState({
            status: "connecting"
        }, () => {
            regimen.verify().then(
                (e) => {
                    this.setState({
                        status: "connected",
                        loggedIn: e.data
                    });

                    var self = this;
                    if (e.data) {
                        regimen.whoami().then(e => self.setState({
                            userAccount: e.data
                        }))

                    }

                    this.getFriends();

                }
            ).catch((e) => {
                this.setState({
                    status: "failure",
                    errorInfoResponse: e.errorInfo.response ? e.errorInfo.response : null,
                })
            })
        })
    }

    componentDidMount() {
        this.attemptConnection();

        // WebSockets
        // ServerSentEvents
        // LongPolling
        initSignalR("ServerSentEvents").then(() => {
            this.setState({
                initSockets: true
            })
            connection.on("profileUpdate", (data) => this.handleWebSocket("profileUpdate", data));
            connection.on("friendRequest", (message) => this.handleWebSocket("friendRequest", message));
            connection.on("friendInfoUpdate", (message) => this.handleWebSocket("friendInfoUpdate", message));
        }).catch(() => {
            // handle error 
        });

    }


    updateState(name, value, callback) {
        console.log(name, value)
        this.setState({
            [name]: value
        }, () => {
            if (callback)
                callback();
        })
    }

    render() {
        return (
            <UserContext.Provider value={{
                ...this.state,
                updateState: this.updateState,
                attemptConnection: this.attemptConnection
            }}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserContextProvider;