import React, { Component, createRef } from "react";
import { UserContext } from "../../contexts/UserContext";
import { connection, helpers } from "../../main";
import MainPage from "../../reusable/MainPage";
import regimen from "../../rs";
import NotActive from "../Both/NotActive";
import SlickContainer from "../../reusable/SlickContainer";
import Button from "../../reusable/Button";
import BaseImage from "../../special/BaseImage";
import { DateTime } from "luxon";
import CoolSwitch from "../../reusable/CoolSwitch";
import Exercise from "../../reusable/Exercise";

var StartDay = (props) => {

    if (!props.day) return <span>Need day</span>;

    var content;
    if ((props.day.exercises || []).length  === 0) {
        content = (
            <div>
                rest
            </div>
        );
    } else {
        content = props.day.exercises && (
            <div>
                <div>
                    This day contains {props.day.exercises.length} exercises. TIME ASSESSMENT
                </div>
                <ul className="noListPadding">
                    {
                        props.day.exercises && (props.day.exercises.map((exercise, index) => {
                            return <li key={index} ><Exercise exercise={exercise} /></li>
                        }))
                    }
                </ul>
            </div>
        );
    }

    return (
        <div>
            <Button onClick={props.return}>
                Cancel
            </Button>
            <div className="startDay">
                {content}
            </div>
        </div>
    )
}

class ViewRegimen extends Component {


    static contextType = UserContext;

    constructor(props) {
        super(props);

        var showOriginal;
        if (helpers.localStorageAccess("showOriginalImage") !== null) {
            showOriginal = helpers.localStorageAccess("showOriginalImage");
        } else {
            showOriginal = false;
        }


        this.state = {
            errorPage: [],
            imageInfo: {},
            day: {},
            showOriginal,
            image: 1,
            showImage: true,
            regimenWeekAmount: 0,
            imageLoaded: false,
            fakeImage: false,
            startingDay: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleWebSocket = this.handleWebSocket.bind(this);
        this.validateURL = this.validateURL.bind(this);
        this.changeDay = this.changeDay.bind(this);

        this.dayRef = React.createRef();
    }


    handleInputChange(event) {
        const target = event.target;
        var value;
        if (target.type === "number") {
            value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
        } else if (target.type === "checkbox") {
            value = target.checked;
        } else {
            value = target.value;
        }

        const name = target.name;

        this.setState({
            [name]: value.toLowerCase(),
            searchError: ""
        });
    }


    handleWebSocket(name, data) {
        // switch (name) {
        //     case "profileTrack":

        //         console.log(data)
        //         if (typeof data ==== "object") { // must be object because sometimes it might not be.

        //             this.setState({
        //                 accountInfo: data
        //             });
        //         }
        //         break;

        //     default:
        //         break;
        // }
    }

    validateURL() {
        // make array of "regimenName" property from regimenList

        var errorPage = [];
        var day = {
            day: this.props.params[2],
            week: this.props.params[1],
            regimen: this.props.params[0]
        }

        var changed = false;

        if (!this.props.params[0]) {
            // this.props.changePage(`/r/${regimen.getRegimenNameList()[regimen.getRegimenNameList().length - 1]}/1/1`);
            alert("must add viewing page")
        }

        // check if day.day is a number and is not 0

        if (isNaN(day.day) || day.day === '0' || day.day === null) {
            day.day = 1;
            changed = true;
        }

        // check if day.week is a number and is not 0

        if (isNaN(day.week) || day.week === '0' || day.day === null) {
            day.week = 1;
            changed = true;
        }

        if (!regimen.getRegimenNameList().includes(this.props.params[0])) {
            if (regimen.getRegimenIDList().includes(this.props.params[0])) {
                day.regimen = helpers.getRegimenNameFromID(this.props.params[0]);
                changed = true;
            } else {
                errorPage.push("regimenName");
            }
        }

        if (errorPage) this.setState({
            errorPage: errorPage
        })

        var cRegimen = regimen.getRegimen(this.props.params[0]);

        if (cRegimen) {
            if (this.props.params[1] > cRegimen.weekAmount) {
                day.week = cRegimen.weekAmount;
                changed = true;
            }
        }

        if (this.props.params[2] > 7) {
            day.day = 1;
            day.week++;
            changed = true;
        }

        if (changed) {
            this.props.changePage(`/r/${day.regimen}/${day.week}/${day.day}`);
            return;
        }

        if (errorPage.length === 0) {

            if (this.dayRef && this.dayRef.current && this.dayRef.current.cancel) {
                this.dayRef.current.cancel()
            }

            console.log("running")
            this.dayRef.current = regimen.cancellableRetrieveDay(day.regimen, day.week, day.day);


            this.dayRef.current.request(day.regimen, day.week, day.day).then((day) => {

                var information = day.data;
                var currentDate = DateTime.fromISO(information.date);

                var showImage = true;
                if (information.images[0].includes("regimen=error")) {
                    information.image = "";
                    showImage = false;
                }

                var self = this;
                this.setState({
                    image: 1,
                    imageLoaded: false,
                }, () => {
                    self.setState({
                        day: information,
                        imageInfo: {
                            weekno: "WEEK " + information.week,
                            dayno: "DAY " + information.day,
                            boldday: information.exercises.length,
                            regimenname: information.regimenName,
                            plaindaytext: `FOR DAY ${information.day}, ${currentDate.toFormat("EEEE").toUpperCase()}`,
                            date: currentDate.toFormat("dd MMM yyyy"),
                            weekdates: `${currentDate.startOf('week').toFormat("dd MMM")} - ${currentDate.endOf('week').toFormat("dd MMM")}`,
                            daydate: currentDate.toFormat("EEEE dd MMM"),
                            daystamp: `Week ${information.week}; Day ${information.day} (` + currentDate.toFormat("EEE") + ")",
                            fakeImage: true
                        },
                        showImage: showImage,
                        regimenWeekAmount: cRegimen.weekAmount
                    }, () => {
                        // check if image is loaded after 500 ms
                        setTimeout(() => {
                            if (!self.state.imageLoaded) {
                                self.setState({
                                    fakeImage: true
                                })
                            }
                        }, 500);
                    })
                })



            });

        }

    }

    componentDidMount() {
        if (this.props.active) {
            document.addEventListener("keydown", this.onKeyPressed.bind(this));
            this.validateURL()
        };


    }

    componentWillUnmount() {
        this.handleWebSocket = () => null;

        document.removeEventListener("keydown", this.onKeyPressed.bind(this));
    }

    onKeyPressed(e) {
        if (e.keyCode === 37) {
            this.changeDay("day", "down");
        } else if (e.keyCode === 39) {
            this.changeDay("day", "up");
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.active) {
            document.removeEventListener("keydown", this.onKeyPressed.bind(this));
            return;
        } else if (this.props.active && !prevProps.active) {
            document.addEventListener("keydown", this.onKeyPressed.bind(this));
        }

        var i = prevProps.params.length;
        while (i--) {
            if (prevProps.params[i] !== this.props.params[i]) {
                this.validateURL();
                console.log("changed")
                return;
            };
        }
        return true

    }

    changeDay(changing, direction) {
        var regName = this.state.day.regimenName;
        var day = this.state.day.day;
        var week = this.state.day.week;
        if (changing === "day") {
            // if day is going to be above 7, set it to one and up the week by one
            if (direction === "up") {
                if (day === 7) {
                    if (week === this.state.regimenWeekAmount) {
                        return;
                    }
                    day = 1;
                    week++;
                } else {
                    day++;
                }
            } else {
                if (day === 1) {
                    if (week === 1) {
                        return;
                    }
                    day = 7;
                    week--;
                } else {
                    day--;
                }
            }
        } else if (changing === "week") {
            if (direction === "up") {
                if (week === this.state.regimenWeekAmount) {
                    return;
                }
                week++;
            } else {
                if (week === 1) {
                    return;
                }
                week--;
            }
        } else {
            regName = changing;
        }

        this.props.changePage(`/r/${regName}/${week}/${day}`);
    }

    render() {

        if (!this.props.active) return <NotActive />

        var specialBar = [

        ];


        var regimenName = ((this.state.day || {}).regimenName) || "";

        var regimenListElem = regimen.getRegimenList().map((regimen, index) => {
            var dull = false;
            if (regimen.regimenName === regimenName) dull = true;
            return (
                <Button key={index} dull={dull} additionalSpacingX={30} additionalSpacingY={15} className={"sc"} onClick={() => {
                    this.props.changePage(`/r/${regimen.regimenName}/${this.props.params[1] || 1}/${this.props.params[2] || 1}`);
                }}>
                    {regimen.regimenName} - {regimen.name}
                </Button>
            )
        })

        if (this.state.errorPage.length > 0) {
            return (
                <MainPage noExtraPadding={true} title="error" className="regimen" active={this.props.active}
                    specialBar={specialBar}
                >
                    <div className="errorPage">
                        You've given an invalid regimen name, <code>{this.props.params[0]}</code>.
                    </div>
                    <p>
                        The valid regimens are:<br />
                        {regimenListElem}
                    </p>
                </MainPage>
            )
        }

        var self = this;

        var switchItems = this.state.showImage ? [
            "Original Image", "Recreation"
        ] : ["No Image", "Recreation Only"];

        var restDay = !(this.state.day && this.state.day.exercises && (this.state.day.exercises.length > 0)) || false;

        return (
            <MainPage noExtraPadding={true} title={`${regimenName || "regimen"}`} className="regimen" active={this.props.active}
                specialBar={specialBar}
            >

                <div>
                    <Button tabIndex={"-1"} onClick={() => this.changeDay("week", "up")}>
                        Week +
                    </Button>
                    <Button tabIndex={"-1"} onClick={() => this.changeDay("week", "down")}>
                        Week -
                    </Button>
                    <Button tabIndex={"-1"} onClick={() => this.changeDay("day", "up")}>
                        Day +
                    </Button>
                    <Button tabIndex={"-1"} onClick={() => this.changeDay("day", "down")}>
                        Day -
                    </Button>
                    <div>
                        <Button disabled={restDay} onClick={
                            () => {
                                this.setState({
                                    startingDay: !this.state.startingDay,
                                })
                            }
                        }>
                            {!this.state.startingDay ? "Start Day" : "Cancel"}
                        </Button>
                    </div>
                    <div>
                        {/* display regimen name, week, and day */}
                        {this.state.day && this.state.day.regimenName} - week {this.props.params[1]} - day {this.props.params[2]}
                    </div>
                </div>
                <SlickContainer className="centralSC" transitionType="transform"
                    items={[
                        (<div>
                            {/* {helpers.weekDayToDate(this.props.params[0], this.props.params[1], this.props.params[2])} */}
                            {
                                !!this.state.day && (
                                    <>




                                        {this.state.showImage && (<div className="ninety" style={{ marginTop: "10px" }}>
                                            <CoolSwitch
                                                items={
                                                    switchItems
                                                }
                                                switchCommand={() => {
                                                    if (!this.state.showImage ) {
                                                        return
                                                    }
                                                    console.log("switched");
                                                    helpers.localStorageAccess("showOriginalImage", !this.state.showOriginal)
                                                    this.setState({
                                                        showOriginal: !this.state.showOriginal
                                                    })
                                                }}
                                                active={(this.state.showOriginal && this.state.showImage) ? 0 : 1}
                                                buttonProps={{ additionalSpacingY: 15 }}
                                            />
                                            <p>
                                                Showing {this.state.showOriginal ? "original image" : "a recreation of the image"} {(!this.state.imageLoaded && this.state.showOriginal) && "(loading original...)"}
                                            </p>
                                        </div>)}

                                        {
                                            ((this.state.day || {}).info || (this.state.day.weekInfo || {}).info) && (
                                                <div style={{ marginTop: "5px" }}>
                                                    {!!this.state.day.weekInfo.info && <span>
                                                        This week has some information attached: <i>"{this.state.day.weekInfo.info}"</i>
                                                    </span>}
                                                    {!!this.state.day.info && <div style={{ marginTop: "5px" }}>
                                                        This day has some information attached: <i>"{this.state.day.info}"</i>
                                                    </div>}
                                                </div>
                                            )
                                        }

                                        <div className={`${(!this.state.fakeImage && (this.state.showOriginal && this.state.showImage)) ? "hidden" : ""}`}>
                                            <BaseImage head={{
                                                width: "100%",
                                                style: { display: "block", maxWidth: "100%", margin: "0 auto" }
                                            }} exercise={this.state.day.exercises}
                                                {...this.state.imageInfo} prodno={this.state.day.regimenName} captiontext={this.state.day.regimen}
                                            />
                                        </div>

                                        {(this.state.showImage && this.state.showOriginal) && (
                                            <div className={`${!this.state.showOriginal ? "hidden" : ""}`}>

                                                {
                                                    this.state.showOriginal && this.state.day.images && (this.state.day.images.length > 1) && (
                                                        <div style={{ marginTop: "10px" }}>
                                                            <CoolSwitch
                                                                items={
                                                                    this.state.day.images.map((_image, index) => "" + (index + 1))
                                                                }
                                                                switchCommand={(function (index) {
                                                                    self.setState({
                                                                        image: index + 1
                                                                    })
                                                                })}
                                                                active={this.state.image - 1}
                                                                buttonProps={{ additionalSpacingY: 15 }}
                                                                className="ninety"
                                                            />
                                                        </div>
                                                    )
                                                }


                                                {
                                                    this.state.day.images && this.state.day.images.length > 0 && (
                                                        <SlickContainer className="centralSC" transitionType="transform"
                                                            items={this.state.day.images.map((image, index) => {
                                                                var onLoad;
                                                                if (index === 0 && !this.state.imageLoaded) {
                                                                    onLoad = () => {
                                                                        this.setState({
                                                                            imageLoaded: true,
                                                                            fakeImage: false
                                                                        })
                                                                    }
                                                                }
                                                                return (
                                                                    <div key={index} className="imageContainer">
                                                                        <img alt="" src={regimen.getAddress() + "/" + image} className="regimenImage" onLoad={onLoad} />
                                                                    </div>)
                                                            })}
                                                            active={this.state.image}
                                                            noMargin={true}
                                                            padding={"0"}
                                                        />

                                                    )
                                                }



                                            </div>
                                        )}

                                        <div style={{ marginTop: "10px" }}>
                                            {regimenListElem}
                                        </div>

                                    </>
                                )
                            }
                        </div>),
                        (
                            <StartDay active={this.state.startingDay} day={this.state.day} return={() => {
                                this.setState({
                                    startingDay: false
                                })
                            }} />
                        )
                    ]}
                    active={!this.state.startingDay ? 1 : 2}
                    noMargin={true}
                    padding={"0"}
                />




            </MainPage>
        )
    }
}

export default ViewRegimen;