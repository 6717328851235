// import Link from "../../reusable/Link";
import {memo, useContext, useRef, useState} from "react"; 
import MainPage from "../../reusable/MainPage";
import Button from "../../reusable/Button";
import CoolSwitch from "../../reusable/CoolSwitch";
import SlickContainer from "../../reusable/SlickContainer";
import { helpers } from "../../main";
import regimen from "../../rs";
import { UserContext } from "../../contexts/UserContext";
import Terms from "../../reusable/documents/Terms";
import PrivPolicy from "../../reusable/documents/PrivPolicy";

var crypticText = [
    'we\'ve been waiting',
    'finally',
    "join us",
    "you have regimenvision",
    "join the movement",
    "join the regimen",
    "the wait was worth it",
    "today, you ascend",
    "begin to begin",
    "you'll love it here",
    "welcome",
];

var randomNumber = Math.floor(Math.random() * crypticText.length);
var textString = crypticText[randomNumber];

if (Math.floor(Math.random() * 1001) === 420) {
    textString = "mujtabaa is not an official representative";
}


const Join = (props) => {

    const userContext = useContext(UserContext)
    
    const [userInfo, setUserInfo] = useState({
        email: "",
        password: "",
        username: "",
    });
    
    function handleInput(e) {

        let { name, value } = e.target;
        value = value.toLowerCase();
        
        
        console.log(name)

        if (name === "email" || name === "username") {
            value = value.trim();
        }

        setLoginStatus("");

        if (name === "username" && mode === 0 && value.length > 3 && helpers.validUsername(value)) {
            setUsernameValid("unknown")
            window.clearTimeout(usernameTO.current)
            usernameTO.current = window.setTimeout(() => {
                regimen.checkUser(value).then((r) => {
                    setUsernameValid(!r.data ? "valid" : "invalid")
                });
                
            }, 600);
        }

        if (name === "email" && mode === 0 && value.length > 3 && helpers.validEmail(value)) {
            setEmailValid("unknown")
            window.clearTimeout(emailTO.current)
            emailTO.current = window.setTimeout(() => {
                regimen.checkUser(value).then((r) => {
                    setEmailValid(!r.data ? "valid" : "invalid")
                });
                
            }, 600);
        }

        // var newUserInfo = {...userInfo};
        // newUserInfo[value] = name;
        // setUserInfo(newUserInfo);

        setUserInfo(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    var resetButton = !!(userInfo.email.length || userInfo.password.length || userInfo.username.length);

    

    const [mode, setMode] = useState(0);
    var validEmail = helpers.validEmail(userInfo.email);
    var validUsername = helpers.validUsername(userInfo.username) && userInfo.username.length > 3;
    var validPassword = userInfo.password.length >= 3;
    
    const usernameTO = useRef(null);
    const [usernameValid, setUsernameValid] = useState("unknown");

    const emailTO = useRef(null);
    const [emailValid, setEmailValid] = useState("unknown");

    const [confirming, setConfirming] = useState(false);

    var acceptable = mode === 0 ? !!(
        (validEmail && validUsername && validPassword && (usernameValid === "valid")) 
    ) : (
        (validUsername) && validPassword
    )

    console.log(acceptable)
    
    const [loginStatus, setLoginStatus] = useState("");

    var proceed = () => {
        if (mode === 0) {
            setConfirming(true);
        } else {
            setLoginStatus("Logging you in...");
            regimen.login(userInfo.username, userInfo.password).then(() => {
                userContext.attemptConnection();
                props.changePage("/")
            }).catch((r) => {
                setLoginStatus(r.info);
            })
        }
    }



    var specialBar = [
        {
            last: {
                title: "Reset",
                dull: 1,
                func: () => setUserInfo({
                    email: "",
                    password: "",
                    username: "",
                }),
                active: resetButton
            },
            active: !confirming
        }, {
            first: {
                title: "Back",
                dull: 1,
                func: () => setConfirming(false),
                active: 1
            },
            active: confirming
        }
    ]

    const [legalStuff, setLegalStuff] = useState({
        pp: false,
        toc: false
    })

    var makeAccount = () => {
        regimen.createAccount(userInfo.username, userInfo.email, userInfo.password).then(() => {
            userContext.attemptConnection();
            props.changePage("/welcome")
        }).catch((r) => {
            setLoginStatus(r.info);
        })
    }


    return (
        <>
            <MainPage title={textString} className="joinPage" active={props.active} specialBar={specialBar}>
                {/* I bet this is the coolest shit you have ever seen
                <br/><Link to={"/home"} 
                    changePage={props.changePage}
                >Go Home.</Link> */}

                <CoolSwitch
                    items = {
                        [!confirming ? "Creating" : "Finishing", "Logging In"]
                    }
                    switchCommand = {!confirming ? setMode : () => {}}
                    active = {mode}
                    buttonProps={{additionalSpacingY: 15}}
                />

                <SlickContainer
                    transitionType="transform"
                    items = {
                        [
                            <div>
                                <div className="subdivision">
                                    <div>
                                        You're on <b>the verge of greatness</b>.
                                    </div>
                                    <div>
                                        You are <b>about to create an account</b> for <span className="sc">regimensocial</span>.
                                    </div>
                                </div>
                                
                                <div className="subdivision">
                                    <div>
                                        You're about to create an account with the username, @<b>{userInfo.username}</b>
                                    </div>
                                    <div>
                                        and the email, <b>{userInfo.email}</b>
                                    </div>
                                </div>

                                <div className="subdivision">
                                    <div>
                                        Please check these are correct, then read our <span onClick={() => setLegalStuff(prevState => ({...prevState, pp: !legalStuff.pp }))} className="fakeLink">privacy policy</span>, along with our <span onClick={() => setLegalStuff(prevState => ({...prevState, tos: !legalStuff.tos }))} className="fakeLink">terms and conditions</span>.
                                    </div>
                                </div>

                                <div className="subdivision">
                                    {legalStuff.pp && <PrivPolicy/>}
                                </div>

                                <div className="subdivision">
                                    {legalStuff.tos && <Terms/>}
                                </div>

                                <div className="continueContainer">
                                    <div className="halfButton">
                                        <Button onClick={() => setConfirming(false)} fontSize="16px" width="100%" dull={1} dullShaded={1} additionalSpacingY={20} round="20">
                                            Go back
                                        </Button>
                                    </div>

                                    <div className="halfButton">
                                        <Button onClick={makeAccount} fontSize="16px" width="100%" additionalSpacingY={20} round="20">
                                            I'm ready
                                        </Button>
                                    </div>
                                </div>

                                
                            </div>,
                            <SlickContainer
                                transitionType="transform"
                                items = {
                                    [
                                        (
                                            <>
                                                <div>
                                                    You must join us. Please enter some details below.
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    return false
                                                }}>
                                                    <div><b>Username</b></div>
                                                    <input type="text" autoComplete="username" name="username" value={userInfo.username} onChange={handleInput}/>
                                            
                                                    <div><b>Email</b></div>
                                                    <input type="email" autoComplete="email" name="email" value={userInfo.email} onChange={handleInput}/>
                                            
                                                    <div><b>Password</b></div>
                                                    <input type="password" autoComplete="new-password" name="password" value={userInfo.password} onChange={handleInput}/>
                                            
                                                    <div>Continue</div>
                                                    <div className="submit">
                                                        <Button width="100%" disabled={mode === 1 || !acceptable} fontSize="16px" additionalSpacingY={25} round="25" onClick={proceed}>
                                                            {mode === 1 || !acceptable ? "Not yet" : "Let's Go"}
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        {
                                                            (resetButton) && (
                                                                <>
                                                                    {(!validUsername && !!userInfo.username.length) ? <div className="problem">
                                                                        Please check <b>your username</b>. 
                                                                        <div>It must be over 4 characters long and contain no special characters. Underscores are okay!</div>
                                                                    </div> :  !!userInfo.username.length && <>
                                                                        {usernameValid === "invalid" && <div>Please choose another username. </div>}
                                                                        {usernameValid === "unknown" && <div>Just checking your username... </div>}
                                                                    </>}
                                                                    {(!validEmail && !!userInfo.email.length) ? <div className="problem">
                                                                        Please check <b>your email</b>. 
                                                                        <div>It must follow the standard email format.</div>
                                                                    </div> :  !!userInfo.email.length && <>
                                                                        {emailValid === "invalid" && <div>Please choose another email. </div>}
                                                                        {emailValid === "unknown" && <div>Just checking your email... </div>}
                                                                    </>}
                                                                    {(!validPassword && !!userInfo.password.length) && <div className="problem">
                                                                        Please check <b>your password</b>. 
                                                                        <div>It must be over 4 characters long.</div>
                                                                    </div>}

                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    
                                                </form>
                                                    
                                            </>
                                        ),
                                        (
                                            <>
                                                <div>
                                                    Welcome back.
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    return false
                                                }}>
                                                    <div><b>Username</b></div>
                                                    <input type="text" autoComplete="username" name="username" value={userInfo.username} onChange={handleInput}/>
                                            
                                                    <div><b>Password</b></div>
                                                    <input type="password" autoComplete="current-password" name="password" value={userInfo.password} onChange={handleInput}/>
                                            
                                                    <div>Continue</div>
                                                    <div className="submit">
                                                        <Button width="100%" disabled={!acceptable} fontSize="16px" additionalSpacingY={25} round="25" onClick={proceed}>
                                                            {!acceptable ? "Not yet" : "Let's Go"}
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        {
                                                            (resetButton) && (
                                                                <>
                                                                    {(!validUsername && !!userInfo.username.length) && <div className="problem">
                                                                        Please check <b>your username</b>. 
                                                                        <div>It must be over 4 characters long and contain no special characters. Underscores are okay!</div>
                                                                    </div>}
                                                                    {(!validPassword && !!userInfo.password.length) && <div className="problem">
                                                                        Please check <b>your password</b>. 
                                                                        <div>It must be over 3 characters long.</div>
                                                                    </div>}
                                                                    {(loginStatus !== "No attempt") && <div>
                                                                        {loginStatus}
                                                                    </div>}
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </form>
                                                    
                                            </>
                                        )
                                    ]

                                }
                                active={mode + 1}
                                noMargin={true}
                            />
                        ]
                    }
                    active = {confirming && mode === 0 ? 1 : 2}
                />
            </MainPage>
        </>
    )
}

export default memo(Join);