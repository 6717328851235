import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import UserContextProvider from './contexts/UserContext';
import history from './history';
// import inobounce from "inobounce";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
	Router
} from "react-router-dom";

// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

const CustomRouter = ({ history, ...props }) => {
	const [state, setState] = useState({
	  	action: history.action,
	  	location: history.location
	});
  
	useLayoutEffect(() => history.listen(setState), [history]);
  
	return (
	  	<Router
			{...props}
			location={state.location}
			navigationType={state.action}
			navigator={history}
	  	/>
	);
};

ReactDOM.render(
    <React.StrictMode>  
        <UserContextProvider>
            
			<CustomRouter history={history}>
                <App />
            </CustomRouter>
        </UserContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


// serviceWorkerRegistration.register();

// document.body.addEventListener('touchmove', function(e) { 
//     e.preventDefault(); 
// });

// inobounce.enable();

console.log(
  "%cregimensocial © 2021\n%cversion three. ",
  "color:white; font-weight: bold; font-family:system-ui; background-color: black; padding-left: 2px; font-size: 2em; font-variant: small-caps; text-shadow: 2px 2px red", "color:white; font-weight: bold; font-family:system-ui; background-color: black; padding-left: 2px; font-size: 1em; font-variant: small-caps; text-shadow: 2px 2px red"
);

var jQuery = (x) => {
	console.log("I don't know why some sites include JQuery when they're also using React. \nMaybe they're using it for its other features besides changing the page, but it seems bloaty to me. ")
	console.log("hahaha")

	setTimeout((() => {
		console.log("I've learnt to hate React throughout this whole process though, mainly in its relation to design and interference of updating upper elements.")
		setTimeout((() => {
			console.log("My A level computer science project will be using Rust and webassembly with luck, I've never used them before but I've been using React for about a year as of writing this... It's been tear enducing at times")
		}), 2000);
	}), 2000);
	
}

window["$"] = (x) => {
	jQuery(x);
}