import React from "react";
import { helpers } from "../main";

var Exercise = (props) => {

    var temp = props.exercise;

    var textElem = []
    var originalSecond = temp.second ;
    var newSecond = originalSecond % 60;
    var newMinute = ~~(originalSecond / 60);
    var originalMinute = newMinute;
    newMinute = originalMinute % 60;
    var newHour = ~~(originalMinute / 60);

    var hourText = "hour";
    var minuteText = "minute";
    var secondText = "second";

    if (temp.amount === 0 && temp.rounds > 0 && temp.amountround > 0) {
        temp.amount = temp.rounds * temp.amountround;
        if (temp.per !== "") {
            temp.amount = temp.amount * 2;
        }
    }


    if (newHour > 0) {
        minuteText = "min";
        secondText = "sec";
    }

    if (newSecond > 0) {
        hourText = "hr";
    }

    if (temp.amount > 0 && temp.second === 0) {

        textElem.push(<b>{temp.amount} {helpers.returnPlural(temp.amount, null, temp.type)}</b>);

        if (temp.rounds > 0 && temp.amountround > 0) {
            textElem.push(<span>{' '}({temp.rounds} * {temp.amountround}{(temp.per !== "" ? ` per ${temp.per}` : "")})</span>);
        }

    } else if (originalSecond > 0) {
        textElem.push(
            <b>{temp.amount} {helpers.returnPlural(temp.amount, null, temp.type)} of {((newHour !== 0) ? <span>{newHour} {hourText}{(newHour > 1 ? "s" : "")}{' '}</span> : "")}{((newMinute > 0) ? <span>{newMinute} {minuteText}{(newMinute > 1 ? "s" : "")} </span> : "")}{((newSecond > 0) ? <span>{newSecond} {secondText}{(newSecond > 1 ? "s" : "")}</span> : "")}</b>);

        // textElem += <tspan fontWeight="bold">{(temp.type)} for {((newHour != 0) ? `${newHour} ${hourText}${(newHour > 1 ? "s" : "")} ` : "")}{((newMinute > 0) ? `${newMinute} ${minuteText}${(newMinute > 1 ? "s" : "")} ` : "")}{((newSecond > 0) ? `${newSecond} ${secondText}${(newSecond > 1 ? "s" : "")}` : "")}</tspan>


    } else {
        textElem.push(<b>{temp.type}</b>);
    }

    return (
        <>
            {textElem.map((e, i) => (<React.Fragment key={i}>{e}</React.Fragment>))}
        </>
    )

}

export default Exercise;