import Link from "../../reusable/Link";
import {memo} from "react"; 
import MainPage from "../../reusable/MainPage";

const Home = (props) => {
    
    console.log(props)
    return (
    <>
        <MainPage title={"regimensocial"} className="homePage" active={props.active} specialBar={[
            {
                first: {
                    title: "Edit",
                    dull: 1,
                    func: null,
                    active: true
                },
                
                last: {
                    title: "Cancel",
                    dull: false,
                    func: null,
                    active: true
                },
                active: true
            }
        ]} noTransition={props.noTransition} menuMobileHeight={props.menuMobileHeight}>
            <Link to={"/join"} 
                changePage={props.changePage}
                active={props.active}
            >Join</Link>
            1234
            <p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p><p>hello</p>
        </MainPage>
    </>
)}

export default memo(Home);