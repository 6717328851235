import MainPage from "../../reusable/MainPage"
import NotActive from "../Both/NotActive";

var Home = (props) => {
    if (!props.active) return <NotActive/>
    return (
        <MainPage title="home" className="home" active={props.active}>
            <div>Welcome to <span className="sc">regimensocial</span>.</div>
            <div>This page will be updated.</div>
        </MainPage>
    )
}

export default Home;