import { DateTime } from "luxon";
import { Component, createRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { UserContext } from "../../contexts/UserContext";
import { connection, helpers } from "../../main";
import Button from "../../reusable/Button";
import MainPage from "../../reusable/MainPage";
import regimen from "../../rs";
import NotActive from "../Both/NotActive";
import SlickContainer from "../../reusable/SlickContainer";

class ViewUser extends Component {


    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            accountInfo: {},
            searching: false,
            searchUser: "",
            searchError: "",
            removingFriend: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleWebSocket = this.handleWebSocket.bind(this);
        this.addFriend = this.addFriend.bind(this)
        this.searchUser = this.searchUser.bind(this)
        this.removeFriend = this.removeFriend.bind(this)
    }

    searchUser() {
        if (this.state.searchUser === this.context.userAccount.name) {
            this.props.navigate("/profile");
            this.setState({
                searching: false,
                searchUser: ""
            })
            return;
        }

        regimen.checkUser(this.state.searchUser).then((r) => {
            if (r.data) {
                var self = this;
                var newUsername = this.state.searchUser;
                this.setState({
                    searching: false,
                    searchError: ""
                }, () => {

                    self.props.navigate(`/u/${newUsername}`);
                })
                console.log(`/u/${this.state.searchUser}`)
            } else {
                this.setState({
                    searchError: <div className="searchError">I'm sorry, but <b>{this.state.searchUser}</b> is not a part of <span className="sc">regimensocial</span>, please make sure the username is exactly correct and try again.</div>
                })
            }
        })
    }

    handleInputChange(event) {
        const target = event.target;
        var value;
        if (target.type === "number") {
            value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
        } else if (target.type === "checkbox") {
            value = target.checked;
        } else {
            value = target.value;
        }

        const name = target.name;

        if (name === "privacySettings") {
            value = parseInt(value);
        }

        if (!helpers.validUsername(value) || value.length > 32) return;

        this.setState({
            [name]: value.toLowerCase(),
            searchError: ""
        });
    }

    componentDidMount() {
        console.log(this.props.params)
        connection.on("profileTrack", (d) => this.handleWebSocket("profileTrack", d));
        this.componentDidUpdate()
    }

    handleWebSocket(name, data) {
        switch (name) {
            case "profileTrack":

                console.log(data)
                if (typeof data === "object") { // must be object because sometimes it might not be.

                    this.setState({
                        accountInfo: data
                    });
                }
                break;

            default:
                break;
        }
    }

    addFriend() {
        regimen.getUser(this.state.username, "makeFriend").then(r => {
            console.log(r)
            // this.setState({ accountInfo: r.data })
        })
    }

    removeFriend() {
        if (!this.state.removingFriend) {
            this.setState({
                removingFriend: true
            })
        } else {
            regimen.getUser(this.state.username, "endFriendship").then(()=>{})
        }

    }

    componentWillUnmount() {
        this.handleWebSocket = () => null;
    }

    componentDidUpdate() {
        if (!this.props.active) return;
        var username = (this.props.params[0] || "").toLowerCase();


        if (username) {
            if (this.state.username !== username) {
                console.log("not matching")
                if (username === this.context.userAccount.name) {
                    this.props.navigate('/profile');
                    return;
                }

                regimen.getUser(username).then((e) => {

                    console.log("PT INVOKE " + username)
                    connection.invoke("ProfileTrack", username).catch((err) => {
                        console.error(err);
                    });

                    if (e && e.data && typeof e.data === "object") {
                        this.setState({
                            username: username,
                            removingFriend: false,
                            accountInfo: e.data
                        })
                    }

                })


            }
        }


    }

    render() {

        if (!this.props.active) return <NotActive />

        // var username = (this.props.params[0] || "").toLowerCase()
        var user = ((this.state.accountInfo && this.state.accountInfo.friend) ? this.state.accountInfo.friend : {})

        if (!user && !user["groups"]) return <div>Loading.</div>

        // console.log(user)
        // if (!user.name) {
        //     return (
        //         <div>
        //             H
        //         </div>
        //     )
        // }


        var statusElement;

        if (this.state.accountInfo) {
            switch (this.state.accountInfo.status) {
                case "notRequested":
                    statusElement = <div className="friendInfo">You can <b>request to be this person's friend</b>, click above! </div>
                    break;
                case "fan":
                    statusElement = <div className="friendInfo">This person <b>wants to be your friend</b>, click above! </div>
                    break;
                case "friends":
                    statusElement = <div className="friendInfo bold">You're friends with this person!</div>
                    break;
                case "requested":
                    statusElement = <div className="friendInfo">You've requested to be friends with this person. They should hopefully respond soon.</div>
                    break;

                default:
                    break;
            }
        }

        var specialBar = [
            {

                first: {
                    title: "Search",
                    dull: true,
                    func: () => this.setState({ searching: true }),
                    active: true
                },
                active: (!this.state.searching && user.name)
            },
            {

                first: {
                    title: "Return",
                    dull: true,
                    func: () => this.setState({ searching: false }),
                    active: true
                },
                active: (this.state.searching && user.name)
            },

            {

                last: {
                    title: "Add Friend",
                    dull: false,
                    func: this.addFriend,
                    active: true
                },
                active: this.state.accountInfo.status === "notRequested"
            },

            {
                last: {
                    title: "Add Back",
                    dull: false,
                    func: this.addFriend,
                    active: true
                },
                active: this.state.accountInfo.status === "fan"
            }
        ];

        return (
            <MainPage noExtraPadding={true} title="people" className="profile" active={this.props.active}
                specialBar={specialBar}
            >

                <SlickContainer className="centralSC" transitionType="transform"
                    items={[
                        (<div>

                            {statusElement}

                            {/* {this.state.accountInfo.requited && <div className="friendMessage">your friend</div>} */}

                            <div className="username">
                                @{this.state.username}
                            </div>


                            {!!user["bio"] && (
                                <div className="bio">"{user["bio"]}"</div>
                            )}
                            {user["timestamp"] ? <div className="timestamp">Joined <span className="sc">regimensocial</span> {DateTime.fromISO(user["timestamp"]).toRelative()}</div> :
                                <div className="timestamp">A member of <span className="sc">regimensocial</span></div>}

                            <img className="profilePicture" src={regimen.getAccountImage(user["picture"], user["pictureCount"])} alt="" />

                            {!!((user["groups"] || []) > 0) && (
                                <>
                                    <div className="groupInfo">They're in {user["groups"] ? user["groups"].length : 0} {helpers.plural("group", user["groups"] ? user["groups"].length : 0)}.</div>
                                    <ul className={"noListPadding infoList"}>
                                        {(user["groups"] || []).map((group, index) => {
                                            return (
                                                <li key={index}>
                                                    {group}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </>
                            )}

                            {this.state.accountInfo.status === "friends" && (
                                <div className="subdivision fakeLink" onClick={this.removeFriend}>
                                    {!this.state.removingFriend ? "Remove this friend?" : "Are you sure?"}
                                </div>
                            )}


                        </div>),
                        <div>
                            Search for a user below!
                            <div className="searchingUser">
                                <TextareaAutosize
                                    className={"regularText"}
                                    name={"searchUser"}
                                    value={this.state.searchUser}
                                    onChange={this.handleInputChange}
                                    onKeyUp={e => helpers.keyCallback((e.key), {
                                        enter: () => this.searchUser()
                                    })}
                                    maxLength={32}
                                />
                                <Button width="100%" additionalSpacingY={20} round="20" onClick={this.searchUser}>
                                    Search
                                </Button>
                            </div>
                            {this.state.searchError}
                        </div>
                    ]}
                    active={(!this.state.searching && user.name) ? 1 : 2}
                    noMargin={true}
                    padding={"0"}
                />




            </MainPage>
        )
    }
}

export default ViewUser;