
import Central from '../Central';
import Home from '../pages/LoggedIn/Home';

import regimen from '../rs';
import About from '../pages/Both/About';
import Welcome from '../pages/LoggedIn/Welcome';
import Profile from '../pages/LoggedIn/Profile';
import ViewUser from '../pages/LoggedIn/ViewUser';
import ViewGroup from '../pages/LoggedIn/ViewGroup';
import ViewRegimen from '../pages/LoggedIn/ViewRegimen';

var LoggedIn = <Central items={{
	about: About,
	
	welcome: Welcome,
	home: Home,
	profile: Profile,
	u: ViewUser,
	g: ViewGroup,
	r: ViewRegimen,
	out: () => <button onClick={regimen.logout}></button>
}} itemProps={{
}} loggedIn={true} />;

export default LoggedIn;