import Button from "./Button"

var CoolSwitch = (props) => {
    return (
        <div className={`coolSwitch ${props.className || ""}`}>
            {props.items.map((x, i) => (
                <div key={i} className={`item ${i === props.active ? "active" : "inactive"}`}>
                    <Button {...props.buttonProps} width="100%" noRound="0" dull={!(i === props.active)} onClick={props.items.length === 2 ? () => props.switchCommand(props.active === 1 ? 0 : 1) : () => props.switchCommand(i)}>
                        {x}
                    </Button>
                </div>
            ))}
        </div>
    )
}

export default CoolSwitch