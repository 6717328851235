const SlickContainer = (props) => {
    const stage = !props.active ? 1 : props.active; 
    var headPadding = props.headPadding || 0;
    var padding = props.padding + (headPadding) || `${10 + (headPadding)}px`;

    if (props.transitionType === "transform") {
        return (
            <div className={`slickContainer perf ${props.className || ""} ${props.noTransition ? "noTransition" : ""}`}>
                <div className={"parent"} style={props.noMargin && {marginTop: "0"}}>
                    {props.header ? 
                        <div style={{
                            paddingTop: padding,
                            paddingLeft: padding,
                            paddingRight: padding,
                            paddingBottom: props.paddingBottom || padding
                        }}>
                            {props.header}
                        </div> 
                    : null}
                    <div className={`central mode${stage}`} style={
                        {
                            transform: `translate3d(${stage > 1 ? `-${(stage - 1) * 100}%` : "0"}, 0, 0)`,
                            transition: `${!props.noTransition ?  `transform ${props.delay || "600ms"}` : ""} ${props.easeIn ?  "ease-in" : ""} ${props.easeOut ?  "ease-out" : ""}`
                        }
                    }>
                        {props.items.map((e, i) => <div key={i} style={
                            {
                                padding: props.padding || "10px",
                                transition: !props.noTransition ? `opacity ${props.fadeTime || "700ms"}` : ""
                            }
                        } className={`object zone${i+1} ${stage === i+1 ? "active" : ""}`}>{e}</div>)}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`slickContainer ${props.className || ""} ${props.noTransition ? "noTransition" : ""}`}>
            <div className={"parent"} 
                style={{
                    marginLeft: `${stage > 1 ? `-${(stage - 1) * 100}%` : "0"}`,
                    transition: !props.noTransition ?  `margin ${props.delay || "600ms"}` : "",
                    transitionDuration: props.delay || "600ms",
                    WebkitTransitionDuration: props.delay || "600ms"
                }}

            >
                {props.header ? 
                    <div style={{
                        paddingTop: padding,
                        paddingLeft: padding,
                        paddingRight: padding,
                        paddingBottom: props.paddingBottom || padding
                    }}>
                        {props.header}
                    </div> 
                : null}
                <div className={`central mode${stage}`} 
                    // {
                    //     transform: `translate3d(${stage > 1 ? `-${(stage - 1) * 100}%` : "0"}, 0, 0)`,
                    //     transition: !props.noTransition ?  `transform ${props.delay || "600ms"}` : "",
                    //     transitionDuration: props.delay || "600ms",
                    //         WebkitTransitionDuration: props.delay || "600ms"
                    // }
                    
                >
                    {props.items.map((e, i) => <div key={i} style={
                        {
                            padding: props.padding || "10px",
                            transition: !props.noTransition ? `opacity ${props.fadeTime || "700ms"}` : "",
                            

                        }
                    } className={`object zone${i+1} ${stage === i+1 ? "active" : ""}`}>{e}</div>)}
                </div>
            </div>
        </div>
    )
}

export default SlickContainer;

// import { useLayoutEffect, useEffect, useRef, useState } from "react";

// const SlickContainer = (props) => {
//     const stage = !props.active ? 1 : props.active; 
//     var headPadding = props.headPadding || 0;
//     var padding = props.padding + (headPadding) || `${10 + (headPadding)}px`;

//     const [newStyle, setNewStyle] = useState("translate(0)")

//     const central = useRef(null);

//     useLayoutEffect(() => {
//         if (central.current) {
//             var target = central.current;
//             var finalAnim = `translateX(${-(stage - 1) * 100}%)`;
//             var player = target.animate([
//                 {transform: finalAnim}
//             ], { 
//                 duration: 600,
//                 fill: 'forwards'
//             });
//             console.log("h")

//             player.addEventListener('finish', function() {
//                 console.log(target.style)
//                 setNewStyle(finalAnim);
//             });
//         }
        
//     }, [stage])

//     if (props.transitionType === "transform") {
//         return (
//             <div className={`slickContainer ${props.className || ""} ${props.noTransition ? "noTransition" : ""}`}>
//                 <div className={"parent"} style={props.noMargin && {marginTop: "0"}}>
//                     {props.header ? 
//                         <div style={{
//                             paddingTop: padding,
//                             paddingLeft: padding,
//                             paddingRight: padding,
//                             paddingBottom: props.paddingBottom || padding
//                         }}>
//                             {props.header}
//                         </div> 
//                     : null}
//                     <div className={`central mode${stage}`} ref={central} style={{transform: newStyle}}>
//                         {props.items.map((e, i) => <div key={i} style={
//                             {
//                                 padding: props.padding || "10px",
//                                 transition: !props.noTransition ? `opacity ${props.fadeTime || "700ms"}` : ""
//                             }
//                         } className={`object zone${i+1} ${stage === i+1 ? "active" : ""}`}>{e}</div>)}
//                     </div>
//                 </div>
//             </div>
//         )
//     }

// }

// export default SlickContainer;