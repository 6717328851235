import * as signalR from "@microsoft/signalr";
import regimen from "./rs";

const useWindowSize = (useState, useLayoutEffect) => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const helperVars = {
    validUsernameRegex: "[a-zA-Z0-9_]",
    timeFormat: "T dd MMM yyyy",
    dayFormat: "dd MMM yyyy",
    exerciseNames: {
        "unset": "Unset",
        "planks": "Plank",
        "sidePlanks": "Side Plank",
        "wallSits": "Wall Sit",
        "bicycleCrunches": "Bicycle Crunch",
        "lunges": "Lunge",
        "squats": "Squat",
        "legsElevatedPushUps": "Legs-elevated Push-up",
        "burpees": "Burpee",
        "regularPushUps": "Push-up",
        "sitUps": "Sit-up",
        "noRecord": "No record :/",
        "noActivity": "No activity :)"
    },
    weekDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
}

var helpers = {
    useWindowSize,
    validUsername: (username) => {
        // const regex = /^[a-zA-Z0-9_]*$/g;

        if (username.length > 32) return false;

        const regex = new RegExp(`^${helperVars.validUsernameRegex}*$`, "g");
        return regex.test(username);
    },
    validEmail: (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    },
    getRegimenIDFromName: (id) => {
        // find "name" property given "regimenName" property
        return ((regimen.getRegimenList().find(e => e.regimenName === id) || []).name || null);
    },

    getRegimenNameFromID: (name) => {
        // find "regimenName" property given "regimenName" property
        return ((regimen.getRegimenList().find(e => e.name === name) || []).regimenName || null);
    },

    readFileDataAsBase64: (e, sendCut = true) => {
        const file = e.target.files[0];

        return new Promise((resolve, reject) => {

            const reader = new FileReader();

            reader.onload = (event) => {


                // if (file.type === "image/png") {
                //     resolve(sendCut ? event.target.result.split(",")[1] : event.target.result);
                //     return;
                // }

                var hiddenCanvas = document.createElement("canvas");
                document.body.appendChild(hiddenCanvas)
                hiddenCanvas.style.visibility = "hidden";
                var context = hiddenCanvas.getContext('2d');

                var newImage = new Image();

                newImage.onload = function () {
                    hiddenCanvas.width = this.width;
                    hiddenCanvas.height = this.height;

                    context.drawImage(newImage, 0, 0);
                    var x = hiddenCanvas.toDataURL("image/png");
                    var value = sendCut ? x.split(",")[1] : x;

                    var imgFileSize = Math.round((value.length) * 3 / 4);
                    console.log(1, value.length)

                    while (imgFileSize > (4 * (1000 * 1000))) {
                        var newSize = (imgFileSize - (4 * (1000 * 1000))) / imgFileSize;

                        x = hiddenCanvas.toDataURL("image/jpeg", Math.round(newSize * 2) / 2);
                        value = sendCut ? x.split(",")[1] : x;
                        imgFileSize = Math.round((value.length) * 3 / 4);

                        console.log(2, value.length)

                    }
                    console.log(newSize)


                    hiddenCanvas.remove();
                    resolve(value);
                    return;

                }
                newImage.src = event.target.result;
            };

            reader.onerror = (err) => {
                reject(err);
            };

            reader.readAsDataURL(file);



        });
    },

    plural: (string, count, h = false) => `${string}${(count > 1 || count === 0) ? (!h ? "s" : "es") : ""}`,

    keyCallback: (key, callbacks) => {
        key = key.toLowerCase();
        if (callbacks[key]) callbacks[key]()
    },

    weekDayToDate: (regID, week, day) => {

        if (!regimen.getRegimenIDList().includes(regID)) {
            regID = helpers.getRegimenIDFromName(regID);
        }

        if (week > 0 && day > 0) {
            // match regimen to property "name" of regimen list and find startDate

            var desiredRegimen = regimen.getRegimenList().find(e => e.name === regID);

            if (!desiredRegimen) return null;

            const startDate = desiredRegimen.startDate;
            if (startDate != null) {
                if (day <= 7) {
                    var temp = new Date(startDate);

                    // add days to temp
                    temp.setDate(temp.getDate() + ((week - 1) * 7) + (day - 1));
                    // return temp in yyyy-MM-dd format
                    return temp.toISOString().split("T")[0];
                }
            } else {
                return null;
            }

        } else {
            return null;
        }
    }, returnPlural: (amount, properName, exerciseType) => {
        if (exerciseType) {
            properName = helperVars.exerciseNames[exerciseType] || exerciseType;
        }
        if (amount > 1 || amount === 0) {

            if (properName === "is") {
                return "are";
            } else if (properName === "has") {
                return "have";
            }

            if (properName.endsWith("ch")) {
                return properName + "es";
            } else {
                return properName + "s";
            }
        } else {
            return properName;
        }
    },
    localStorageAccess: (key, value = null) => {
        var ls = JSON.parse(window.localStorage.getItem("data") || "{}");
        if (value !== null) {
            ls[key] = value;
            console.log(ls)
            window.localStorage.setItem("data", JSON.stringify(ls));
        } else {
            return (ls[key] || null);
        }
    }
}

var connection;

const initSignalR = (method) => {
    return new Promise((resolve, reject) => {
        connection = new signalR.HubConnectionBuilder()
            .withUrl(`${regimen.getAddress()}/centralHub`, {
                transport: signalR.HttpTransportType[method]
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
        connection.start()
            .then(() => {
                console.info("Connected to server via signalR via " + connection.connection.transport.constructor.name);
                resolve("Connected");
            }).catch((e) => {
                console.error(e);
                console.error("Attempted method doesn't work.");
                console.log("SignalR is not working. Live features may not work. Working to fix this. tried connecting via " + method)
                reject(e);
            });
    });
}

export {
    helpers,
    initSignalR,
    connection
}