import { useRef, useState, useEffect, useLayoutEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { helpers } from "../main";

const getTextDimensions = (text, fontSize, additionalSpacingX, additionalSpacingY) => {
    var x = document.createElement("SPAN");
    var t = document.createTextNode(text);
    if (fontSize) {
        x.style.fontSize = fontSize;
    }
    x.appendChild(t);
    x.id = `${Math.floor(Math.random() * 991)}`;
    document.querySelector("#hiddenBox").appendChild(x);
    var result = {
        width: x.offsetWidth + (additionalSpacingX || 10),
        height: x.offsetHeight + (additionalSpacingY || 10)
    }
    x.remove();
    return result;
}


var Button = (props) => {
    const uniqueIdentifier = useRef(`${props.identifier || "unidentified"}-${uuidv4()}`);
    const textRef = useRef();
    const svgRef = useRef();

    const [dimensions, setDimensions] = useState({width: 0, height: 0});
    const windowSize = helpers.useWindowSize(useState, useLayoutEffect);


    useEffect(() => {
        setDimensions(getTextDimensions(props.children, props.fontSize, props.additionalSpacingX, props.additionalSpacingY));
    }, [props.children, props.fontSize, props.additionalSpacingX, props.additionalSpacingY, windowSize])

    var autoSizing = !props.noAMeasure ? {width:(props.width || dimensions.width || 0), height: (props.height || dimensions.height || 0)} : {};

    var newProps = {
        style: {...props.buttonStyle, ...autoSizing},
        disabled: props.disabled,
        tabIndex: props.disabled ? "-1" : "",
        onClick: props.onClick
    }

    var textStyle = {fontSize: props.fontSize};

    var svgWidth = !props.noSVGMeasure ? (props.SVGwidth || props.width || dimensions.width || 0) : null;

    // console.log(svgRef.current ? svgRef.current.clientWidth : "")
    return (
        <button tabIndex={props.tabIndex} className={`newButton ${props.dull ? "dullElement" : ""} ${props.className || ""} ${props.noTransition ? "noTransition" : ""}`} {...newProps}>
            <svg className={`${props.dull ? "dull" : ""}`} width={svgWidth} height={!props.noSVGMeasure ? (props.SVGheight || props.height || dimensions.height || 0) : null} ref={svgRef}>
                
                    <g opacity={!props.dull ? 1 : 0}>
                        <defs>
                            <mask id={uniqueIdentifier.current} x="0" y="0" height="100%" width="100%">
                                <rect x="0" y="0" height="100%" width="100%" style={{
                                    mask: `url(#${uniqueIdentifier.current})`,
                                    WebkitMask: `url(#${uniqueIdentifier.current})`
                                }} />
                                <g width={"100%"}>
                                    <svg style={{maxWidth: "100%"}}>
                                        <text 
                                            x="50%" 
                                            y={"50%"} 
                                            textAnchor="middle" 
                                            dominantBaseline="central" 
                                            fill={"black"}
                                            width="100%"
                                            ref={textRef}
                                            style={textStyle}
                                        >
                                            {props.children}
                                        </text>
                                    </svg>
                                </g>
                            </mask>
                        </defs>
                        <rect x="0" y="0" height="100%" width="100%" rx={!props.noRound ? (props.round ? props.round : "5") : "0"} style={{
                            mask: `url(#${uniqueIdentifier.current})`,
                            WebkitMask: `url(#${uniqueIdentifier.current})`
                        }}/>
                    </g>
                    <g opacity={props.dull ? 1 : 0}>
                        <rect className={`dull ${props.dullShaded ? "shaded" : ""}`} rx={!props.noRound ? (props.round ? props.round : "5") : "0"} x="0" y="0" height="100%" width="100%" style={{
                            mask: `url(#${uniqueIdentifier.current})`,
                            WebkitMask: `url(#${uniqueIdentifier.current})`
                        }} />
                        <text 
                            x="50%" 
                            y={"50%"} 
                            textAnchor="middle" 
                            dominantBaseline="central" 
                            fill={"white"}
                            width="100%"
                            className="dull"
                            ref={textRef}
                            style={textStyle}
                        >
                            {props.children}
                        </text>
                    </g>
                    
                
            </svg>

        </button>
    )
    
}

export default Button;