import MainPage from "../../reusable/MainPage";
import config from "../../config.json";

var currentYear = new Date().getUTCFullYear();

var About = () => {
    var rs = <span className="sc b">regimensocial</span>;
    return (
        <MainPage title={"about"} className="about" specialBar={[]}>
		    
            <div className="centreSection">
                <div className="subdivision">
                    <div>You're on <code>(provisional) regimen3</code> version <div><b>{config.clientName}</b> (released {config.clientDate})</div></div>
                    {/* <div><code>Client info: "{navigator.userAgent}"</code></div> */}
                    <div>hosted at the heart of <i>{window.location.hostname}</i></div>
                </div>
            
                {rs} © 2021 - {currentYear} 
                <div>All rights reserved.</div>
            
                <div className="subdivision">
                    {rs} and <span className="sc b">the regimen</span> is a brand of Jamie Adams.
                </div>
            </div>
	    </MainPage>
    )
}


export default About;