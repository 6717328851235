// import SlickContainer from "./reusable/SlickContainer";
import { useParams, useNavigate, } from "react-router-dom";
import { useContext, useLayoutEffect, useRef, useState, } from "react";
import Button from "./reusable/Button";
import { UserContext } from "./contexts/UserContext";
import MainPage from "./reusable/MainPage";
import { helpers } from "./main";



// var usePrevious = (value) => {
// 	const ref = useRef();
// 	var altVal = undefined;
// 	if (typeof value === "object") {
// 		altVal = {};
// 	}
// 	useEffect(() => {
// 	  ref.current = value;
// 	});
// 	return ref.current || altVal;
// }

// var get24Time = () => {
//     var time = new Date();
//     var hours = time.getHours().toString();
//     var minutes = time.getMinutes().toString();
//     var seconds = time.getSeconds().toString();
//     if (hours.length === 1) {
//         hours = "0" + hours;
//     }
//     if (minutes.length === 1) {
//         minutes = "0" + minutes;
//     }
//     if (seconds.length === 1) {
//         seconds = "0" + seconds;
//     }
//     return `(${hours}:${minutes}:${seconds}) | `
// }

// var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

var menuLoggedIn = [
    ["Home", "home"],
    ["Welcome", "welcome"],
    ["Profile", "profile"],
    ["Social", "u"]
    // ["title", "link"]
]

var menuLoggedOut = [

    [["regimensocial", "sc"], "home"],
    ["Join", "join"],

    ["About", "about"],
]

var otherPages = {
    default3: (props) => {
        var content = "Determining problem";


        if (props.errorInfoResponse) {
            content = (
                <>
                    <div>Something very bad has happened to the central <span className="sc">regimensocial</span> server...</div>
                    <div>We're hopefully working on it now...</div>
                    {props.button}
                </>
            )
        } else {
            content = (
                <>
                    <div>We can't connect to the central <span className="sc">regimensocial</span> server at this moment...</div>
                    <p>This could be a huge problem on our end, or your internet could simply be malfunctioning.</p>
                    <p>Check everything is okay on your end, then please press the big obvious button below.</p>
                    {props.button}
                </>
            )
        }
        return (
            <MainPage title="error">
                {content}
                <p><i>I'm sorry this is happening</i> - Jamie</p>
                <p>If cert not allowed, <a href={props.address}>click here and approve.</a></p>
            </MainPage>
        )
    },
    default2: (props) => (
        <MainPage title="connecting">
            <div className="connectingPage">
                <div><b>Please wait</b></div>

                <p>If cert not allowed, <a href={props.address}>click here and approve.</a></p>

                <Button buttonStyle={{ display: "block" }} width="90%" fontSize="14px" additionalSpacingY={20} onClick={() => window.location.reload()}>
                    Harsh reload
                </Button>

            </div>
        </MainPage>
    ),
    default: () => (
        <MainPage title="not found">
            not found
        </MainPage>
    ),
}

const Central = (props) => {
    let params = useParams();

    let navigate = useNavigate();
    const userContext = useContext(UserContext)

    var page = params.pageAddress ? params.pageAddress.toLowerCase() : "home";
    var noTransition = useRef(true);

    // useLayoutEffect(() => {
    //     if (isIOS && connected) 
    //         noTransition.current = true
    //     else 
    //         noTransition.current = false

    //     register()
    // });

    // console.log(get24Time() + "New render, noTransition.current: " + noTransition.current);
    // console.log(get24Time() + "-")

    var changePage = (page, root = false) => {
        // if (isIOS)
        //     noTransition.current = false;

        navigate((root ? "/" : "") + page)
    }

    var button = (
        <div>
            <p>The big obvious:</p>
            <div>
                <Button width="100%" fontSize="16px" additionalSpacingY={20} onClick={userContext.attemptConnection}>
                    Try reconnect button
                </Button>
            </div>
        </div>
    );

    var menuContent = props.loggedIn ? menuLoggedIn : menuLoggedOut;

    var connected = (userContext.status === "connected" && userContext.initSockets);
    var connectionError = userContext.status === "failure";


    var displayPages = connected ? { ...otherPages, ...props.items } : otherPages;

    var currentPage = (connected ? (Object.keys(displayPages).findIndex(e => e === page) + 1 || 3) :
        (!connectionError ? 2 : 1)
    );

    var dimensions = helpers.useWindowSize(useState, useLayoutEffect);

    var mobile;

    if ([dimensions][0][0] <= 950) {
        mobile = true;
    } else {
        mobile = false;
    }

    // useLayoutEffect(() => {
    //     if (connected) {
    //         setTimeout(() => {
    //             noTransition.current = true;
    //         }, 300);
    //     }
    // }, [connected])

    // noTransition.current = false;

    // noTransition.current = true;

    return (
        <div className="centralMain">
            <div className="containerCentralSC">
                {/* <SlickContainer noTransition={noTransition.current} className="centralSC" transitionType="transform"
                    items={}
                    active={currentPage}
                    noMargin={true}
                    padding={"0"}
                /> */}

                {
                    Object.entries(displayPages).map((item, index) => {
                        var [key, Page] = item;

                        var moreProps = {};
                        if (props.itemProps[key]) {
                            moreProps = props.itemProps[key];
                        }

                        moreProps.active = ((index + 1) === currentPage);
                        moreProps.enableInput = {
                            disabled: true,
                            tabIndex: "-1"
                        }
                        if (moreProps.active) {
                            moreProps.enableInput = {
                                disabled: false,

                            }
                        }

                        if (Object.keys(otherPages).includes(key)) {
                            moreProps = {
                                ...moreProps, ...{
                                    errorInfoResponse: userContext.errorInfoResponse,
                                    button: button,
                                    address: userContext.address
                                }
                            }
                        }

                        moreProps.params = ([params.firstParam || null, params.secondParam || null, params.thirdParam || null, params.fourthParam || null, params.fifthParam || null])
                        moreProps.pageName = key;
                        moreProps.changePage = changePage;
                        moreProps.noTransition = noTransition.current;
                        moreProps.navigate = navigate
                        return (
                            <div key={index} className="centralSC newContainer" style={{ display: moreProps.active ? "block" : "none" }}>
                                <Page {...{ ...props, ...moreProps }} />
                            </div>
                        )
                    })
                }

            </div>


            <div className={`menuBarDesktop  ${!connected ? "hidden" : "active"}`}>
                {menuContent.map((x, i) => (
                    <Button disabled={mobile} key={"mbc" + i} dull={
                        "/" + window.location.pathname.split("/")[1] !== ("/" + x[1])
                    } className={`item ${x[0][1] || ""} ${(x[2]) ? "mobile" : ""}`} onClick={() => changePage(x[1], true)} noRound="true" noAMeasure={1} fontSize="16px" additionalSpacingX={40} additionalSpacingY={30}>
                        {typeof x[0] === "string" ? x[0] : x[0][0]}
                    </Button>
                ))}
            </div>

            <div className={`menuBarMobile ${!connected ? "hidden" : "active"}`}>
                {menuContent.map((x, i) => (
                    <Button disabled={!mobile} key={"mbc" + i} additionalSpacingY={15} dull={
                        "/" + window.location.pathname.split("/")[1] !== ("/" + x[1])
                    } className={`item ${x[0][1] || ""} ${(x[2]) ? "mobile" : ""}`} onClick={() => changePage(x[1], true)} noRound="true">
                        {typeof x[0] === "string" ? x[0] : x[0][0]}
                    </Button>
                ))}
            </div>

            {/* I am the Central element. */}


        </div>
    )
}



export default Central;
