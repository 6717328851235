
import Central from '../Central';
import Home from '../pages/NotLoggedIn/Home';
import Join from '../pages/NotLoggedIn/Join';
import About from '../pages/Both/About';

var LoggedOut = <Central items={{
	home: Home,
	join: Join,
	about: About
}} loggedIn={false} itemProps={{
	
}} />;

export default LoggedOut;