import * as config from "./config.json";
import axios from "axios";
import isEmail from 'validator/lib/isEmail';
import { DateTime } from "luxon";
import { helpers } from "./main";

var production = !(window.location.hostname.includes("192.168") || window.location.hostname.includes("127.0.0.") || window.location.hostname.includes("localhost"));
var address = ((production ? config["production"] : config["development"]).secure ? "https://" : "http://") + (production ? config["production"].host : window.location.hostname) + ":" + (production ? config["production"].port : config["development"].port);

var regimenList = [];

var handleRequest = (action, data, res, rej, cancelSetter = null) => {
    // add request to list and remove when done
    var controller = new AbortController();

    axios.post(`${address}/${action}`, {...data, signal: controller.signal}, { withCredentials: true, headers: { 'Content-Type': 'application/json' } }).then((r) => {
        if (!production) {
            console.log(r.data);
        }
        res(r.data)
    }).catch((r) => {
        if (r.response && r.response.data) {
            if (!production) {
                console.error(r.response.data);
            }
            rej(r.response.data);
        } else {
            console.error("Data missing head");
        }
    })

    
    if (cancelSetter) {
        cancelSetter(controller);
    }
}

var regimen = {
    /**
     * This a void (kind of), checks server online & healthy
     * @returns 
     */
    verify: () => new Promise((resolve, reject) => {
        console.log(
            `[api] %cattempting connection to ${!!production ? "regimensocial central server" : ""} %c${address}`,
            "font-size: 2em; font-weight: bold; font-variant: small-caps;", ""
        );
        axios.post(`${address}/api/test`, {}, { withCredentials: true }).then((r) => {
            resolve(r.data);
            console.log(
                `[api] %c${address} is online and ready for use!`,
                "", ""
            );
        }).catch((r) => {
            alert(JSON.stringify(r.message))
            reject({
                status: "issue",
                info: "Something went wrong (CLIENT SIDE)",
                errorInfo: r
            })
        })
    }),
    /**
     * Loginto account
     * @param {string} username can secretly be an email if you want
     * @param {string} password 
     * @returns 
     */
    login: (username, password) => new Promise((resolve, reject) => {
        handleRequest("api/login", {
            name: !isEmail(username) ? username : null,
            email: isEmail(username) ? username : null,
            password
        }, resolve, reject);
    }),
    logout: () => new Promise((resolve, reject) => {
        handleRequest("api/logout", {
        }, resolve, reject);
    }),
    /**
     * Create account
     * @param {string} username 
     * @param {string} email 
     * @param {string} password 
     * @returns 
     */
    createAccount: (username, email, password) => new Promise((resolve, reject) => {
        handleRequest("api/createAccount", {
            name: username,
            email: isEmail(email) ? email : null,
            password
        }, resolve, reject);
    }),
    /**
     * Tells you if username registered
     * @param {string} username Username of the fella you want to check exists
     * @returns 
     */
    checkUser: (username) => new Promise((resolve, reject) => {
        handleRequest("simple/user", username, resolve, reject);
    }),


    /**
     * gives you user's info
     * @param {string} username 
     * @param {string} action 
     * @returns 
     */
    getUser: (username, action) => new Promise((resolve, reject) => {
        handleRequest("api/specificFriend", {
            friendUsername: username,
            action: action || null
        }, resolve, reject);
    }),

    /**
     * Tells you who you is.
     * @param {string} action null, "update"
     * @param {object} userInfo User's info, you already know what you doing
     * @returns The usual, you can still use this result even if you just made a change
     */
    whoami: (action = null, userInfo = null) => new Promise((resolve, reject) => {
        if (!action) {
            handleRequest("api/whoami", null, resolve, reject);
        } else if (action === "update") {
            handleRequest("api/whoami", {
                action,
                ...userInfo
            }, resolve, reject);
        }
    }),
    /**
     * 
     * @param {object} fields Not helping you here brody, you SHOULD remember this part. Good luck LMAO 😂
     * @returns a literal image, one of the few cases where the result is NOT the usual. Convert to base64 and dish up.
     */

    UNSTABLE_imageTest: (fields) => new Promise((resolve, reject) => {


        handleRequest("api/imageTest", fields, resolve, reject);
        console.warn("High chance this will not be allowed to the end user, a better alternative exists client-side")

    }),

    /**
     * 
     * @param {string} regimen 
     * @param {string} week 
     * @param {string} day 
     * @returns 
     */

    retrieveDay: (reg, week, day) => new Promise((resolve, reject) => {
        if (!regimen.getRegimenIDList().includes(reg)) {
            reg = helpers.getRegimenIDFromName(reg);
        }

        handleRequest("api/retrieveDay", { regimen: reg, week, day }, resolve, reject);
    }),

    cancellableRetrieveDay: (reg, week, day) => {
        var cancel = null;

        var setCancel = (c) => {
            cancel = c;
        }

        if (!regimen.getRegimenIDList().includes(reg)) {
            reg = helpers.getRegimenIDFromName(reg);
        }

        var finished = false;
        var request = () => new Promise((resolve, reject) => {
            finished = true;
            handleRequest("api/retrieveDay", { regimen: reg, week, day }, resolve, reject, setCancel);
        });

        var cancelFunc = () => {
            cancel.abort();
        }

        return ({
            request, 
            cancel: cancelFunc
            
        })
    },

    /**
     * what do you think it does?
     * @param {string} date 
     * @returns 
     */

    retrieveDayDate: (date) => new Promise((resolve, reject) => {
        handleRequest("api/retrieveDay", { date }, resolve, reject);
    }),

    /**
     * Fills in regimen list, CALLED AUOMATIALCIYL.
     * @returns basically a void
     */

    fillRegimenList: () => new Promise((resolve, reject) => {
        handleRequest("simple/regimenList", {
        }, ((r) => {
            regimenList = r.data;
            resolve("Sorted");
        }), reject);
    }),

    /**
     * 
     * @returns the regimen list
     */

    getRegimenList: () => {
        return regimenList;
    },


    /**
     * 
     * @returns regimen name list
     */
    getRegimenNameList: () => {

        return regimenList.map((item) => item.regimenName);
    },

    /**
     * 
     * @returns regimen id list
     */
    getRegimenIDList: () => {

        return regimenList.map((item) => item.name);
    },

    /**
     * 
     * @param {string} name either full name or identifier name
     * @returns regimen info object
     */

    getRegimen: (name) => {
        return regimenList.find(x => (x.name === name || x.regimenName === name))
    },

    getAddress: () => {
        return address;
    },

    /**
     * 
     * @param {string} regimen 
     * @param {string} week 
     * @param {string} day 
     * @param {boolean} jsDate 
     * @returns 
     */

    getDate: (regimen, week, day, jsDate = false) => {
        var currentRegimen = regimenList.find(x => (x.name === regimen || x.regimenName === regimen));
        var result = DateTime.fromISO(currentRegimen.startDate).plus({ days: day - 1, weeks: week - 1 });
        if (jsDate) {
            result = result.toJSDate();
        }
        return result;
    },

    /**
     * 
     * @returns {boolean} if production or not
     */

    isProduction: () => production,

    /**
     * 
     * @param {object} day the full day object in here, including all the actions LMAO, have fun with this one, we coded it in April and it's December tomorrow LMAOOOOO
     * @returns 
     */

    manageExercise: (day) => new Promise((resolve, reject) => {
        handleRequest("api/manageExercise", day, resolve, reject);
    }),

    /**
     * 
     * @param {string} session
     * @returns 
     */
    manageSession: (session) => new Promise((resolve, reject) => {
        // session should be like
        // dataToSend = {
        //     date: this.state.date,
        //     groupName: this.state.groupOrFriends == 1 ? this.state.presetGroups : "",
        //     requestedUsers: this.state.groupOrFriends == 2 ? this.state.friendsToAdd : null
        // };

        handleRequest("api/manageSession", session, resolve, reject);
    }),

    /**
     * 
     * @param {object} day sorry bro I've had a bad day, hope you're okay and remember what this does :) 
     * @returns 
     */

    retrieveTotal: (day) => new Promise((resolve, reject) => {
        handleRequest("api/retrieveTotal", day, resolve, reject);
    }),

    /**
     * 
     * @returns RETURNS YOUR CURRENT FRIENDS
     */

    retrieveFriends: (action = "") => new Promise((resolve, reject) => {
        handleRequest("api/retrieveFriends", { action: action }, resolve, reject);
    }),

    /**
     * 
     * @returns PEOPLE WHO WANT TO BE YOUR FRIEND WHO YOU'VE NOT SAID YES TO YET
     */

    retrieveFriendReqs: () => new Promise((resolve, reject) => {
        handleRequest("api/retrieveFriends", { action: "requests" }, resolve, reject);
    }),

    /**
     * 
     * @returns FRIEND REQUESTS THAT YOU'VE SENT
     */

    retrieveMyRequests: () => new Promise((resolve, reject) => {
        handleRequest("api/retrieveFriends", { action: "myRequests" }, resolve, reject);
    }),


    /**
     * 
     * @param {string} uuid 
     * @param {string} imageCount 
     * @returns 
     */
    getAccountImage: (uuid, imageCount) => `${address}/images/profilePicture/?uuid=${uuid}&imageCount=${imageCount}`,

    /**
     * 
     * @param {string} uuid 
     * @param {string} imageCount 
     * @returns 
     */
    getGroupImage: (uuid, imageCount) => `${address}/images/groupPicture/?uuid=${uuid}&imageCount=${imageCount}`

    

}

regimen.fillRegimenList().catch((e) => {
    console.error(e);
});

window.regimen = regimen;

console.log(
    "%c[api] regimen api © 2021\nhand-crafted by jamie adams",
    "color:white; font-weight: bold; font-family:system-ui; background-color: black; padding-left: 2px; font-size: 2em; font-variant: small-caps; text-shadow: 2px 2px blue"
);

export default regimen;
