import { DateTime } from "luxon";
import { Component, createRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { UserContext } from "../../contexts/UserContext";
import { helpers } from "../../main";
import MainPage from "../../reusable/MainPage";
import regimen from "../../rs";
import NotActive from "../Both/NotActive";


class Profile extends Component {


    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            bio: "",
            newImage: ""
        }

        this.switchEditing = this.switchEditing.bind(this);
        this.saveEditing = this.saveEditing.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.fileUploader = createRef();
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    handleFileChange(e) {
        var self = this;
        helpers.readFileDataAsBase64(e, false).then((file) => {
            self.setState({
                newImage: file
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    handleInputChange(event) {
        const target = event.target;
        var value;
        if (target.type === "number") {
            value = parseInt(target.value);
            if (isNaN(value)) {
                value = 0;
            }
        } else if (target.type === "checkbox") {
            value = target.checked;
        } else {
            value = target.value;
        }

        const name = target.name;

        if (name === "privacySettings") {
            value = parseInt(value);
        }

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        var user = (this.context.userAccount)
        this.setState({
            bio: user["bio"]
        })
    }

    switchEditing() {

        var user = (this.context.userAccount)
        this.setState({
            editing: !this.state.editing,
            bio: user["bio"],
            privacySettings: user["privacySettings"],
        })
    }

    saveEditing() {
        var self = this;

        if (this.state.newImage && (this.state.newImage.length > 4 * (1000 * 1000))) {
            alert("Your image is too big")
            return;
        }

        regimen.whoami("update", {
            bio: this.state.bio.trim(),
            picture: this.state.newImage ? this.state.newImage.split(",")[1] : null,
            privacySettings: this.state.privacySettings
        }).then(r => this.context.updateState("userAccount", r.data, () => {
            self.setState({
                editing: false,
                newImage: ""
            })
        }))
    }

    render() {
        if (!this.props.active) return <NotActive/>

        var user = (this.context.userAccount)

        if (!user && !user["groups"]) return <div>Loading.</div>

        return (
            <MainPage noExtraPadding={true} title="profile" className="profile" active={this.props.active}
                specialBar={[
                    {

                        last: {
                            title: "Edit",
                            dull: true,
                            func: this.switchEditing,
                            active: true
                        },
                        active: !this.state.editing
                    },

                    {
                        first: {
                            title: "Cancel",
                            dull: true,
                            func: this.switchEditing,
                            active: true
                        },
                        last: {
                            title: "Save",
                            dull: false,
                            func: this.saveEditing,
                            active: (
                                this.state.bio !== user["bio"] ||
                                this.state.newImage ||
                                this.state.privacySettings !== user["privacySettings"]
                            )
                        },
                        active: this.state.editing
                    }
                ]}
            >

                {!this.state.editing ? (
                    <div className="normalUL">

                        <div className="username">@{user["name"]}</div>
                        <div className="bio">"{user["bio"]}"

                        </div>

                        <div className="timestamp">Joined <span className="sc">regimensocial</span> {DateTime.fromISO(user["timestamp"]).toRelative()}</div>
                        <img className="profilePicture" src={regimen.getAccountImage(user["picture"], user["pictureCount"])} alt="" />

                        <div className="groupInfo">You're in {user["groups"] ? user["groups"].length : 0} {helpers.plural("group", user["groups"] ? user["groups"].length : 0)}.</div>
                        <ul className={"noListPadding infoList"}>
                            {(user["groups"] || []).map((group, index) => {
                                return (
                                    <li key={index}>
                                        {group}
                                    </li>
                                )
                            })}
                        </ul>

                    </div>) : (
                    <div className="editingUL">
                        <div className="username">@{user["name"]}</div>
                        <div className="bio">
                            {
                                !this.state.editing ? <></> :
                                    <>
                                        <TextareaAutosize
                                            className={"regularText"}
                                            name={"bio"}
                                            value={this.state.bio}
                                            onChange={this.handleInputChange}
                                            disabled={!this.state.editing}
                                            maxLength={300}
                                        />
                                        {
                                            this.state.bio.length > 270 ? <div>{300 - this.state.bio.length} characters remaining</div> : ""
                                        }
                                    </>
                            }
                        </div>

                        <div className="timestamp">Joined <span className="sc">regimensocial</span> {DateTime.fromISO(user["timestamp"]).toRelative()}</div>
                        <input
                            type="file"
                            ref={this.fileUploader}
                            className={"hidden"}
                            name={"newImage"}
                            accept=".png,.jpg,.jpeg"
                            disabled={!this.state.editing}
                            onChange={this.handleFileChange}
                        />
                        <div className="profilePictureContainer" onClick={() => this.fileUploader.current.click()}>
                            <div className="editButton">Tap to change</div>
                            <img className="profilePicture" src={!this.state.newImage ? regimen.getAccountImage(user["picture"], user["pictureCount"]) : `${this.state.newImage}`} alt="" />
                        </div>

                        <div className={"groupMenu"}>
                            Privacy Settings.
                            <ul className={"noListBullet infoList"}>
                                <li>
                                    <input disabled={!this.state.editing} type="radio" id={"ps1"} readOnly={true} value={1} onClick={this.handleInputChange} checked={this.state.privacySettings === 1} name="privacySettings" />
                                    <label htmlFor={"ps1"}>Anyone can see my profile.</label>
                                </li>
                                <li>
                                    <input disabled={!this.state.editing} type="radio" id={"ps2"} readOnly={true} value={2} onClick={this.handleInputChange} checked={this.state.privacySettings === 2} name="privacySettings" />
                                    <label htmlFor={"ps2"}>My friends can see my profile.</label>
                                </li>
                                <li>
                                    <input disabled={!this.state.editing} type="radio" id={"ps3"} readOnly={true} value={3} onClick={this.handleInputChange} checked={this.state.privacySettings === 3} name="privacySettings" />
                                    <label htmlFor={"ps3"}>Only I can see my profile.</label>
                                </li>
                            </ul>

                        </div>
                    </div>
                )}

                {JSON.stringify(this.context.friends)}

                
                {JSON.stringify(this.context.inboundFriends)}

                
                {JSON.stringify(this.context.outboundFriends)}


            </MainPage>
        )
    }
}

export default Profile;