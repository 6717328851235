var Link = (props) => {
    var onClickEvent = (e) => {
        e.preventDefault();
        props.changePage(props.to);
    }
    return (
        <a href={props.to} onClick={onClickEvent} tabIndex={props.active ? null : "-1"}>
            {props.children}
        </a>
    )
}

export default Link;