import * as React from "react";
import { helpers } from "../main";

function BaseImage(props) {
    var restDay = ((props.exercise || []).length === 0);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1280 2272"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={1.5}
            className="dayElement"
            {...props.head}
        >
            {/* <path
        fill="url(#baseFile_svg__a)"
        d="M-190.673-101.245h1457.69v2180.48h-1457.69z"
        transform="rotate(180 621.13 1132.935) scale(1.11909)"
      /> */}
            <path
                fill="#ffffff05"
                d="M-190.673-101.245h1457.69v2180.48h-1457.69z"
                transform="rotate(180 621.13 1132.935) scale(1.11909)"
            />
            <path fill="none" d="M75 76h1129.997v2121.998H75z" />
            <path
                d="M1207.08 73.918H72.917V2200.08H1207.08V73.918zM1204.997 76H75v2121.998h1129.998V76z"
                fill="#fff"
            />
            <text
                textRendering="optimizeLegibility"
                x={-2084.06}
                y={1317.89}
                style={{
                    InkscapeFontSpecification:
                        "'SegoeUI-BoldItalic, Segoe UI, Open Sans, sans-serif, Bold Italic'",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "small-caps",
                    fontVariantNumeric: "normal",
                    fontVariantEastAsian: "normal",
                }}
                fontStyle="italic"
                fontWeight={700}
                fontSize={236.048}
                fontFamily="SegoeUI-BoldItalic,'Segoe UI','Open Sans',sans-serif"
                fill="#fff"
                transform="matrix(.18213 0 0 .19754 491.132 40.303)"
            >
                <tspan
                    x={-2084.06}
                    y={1317.89}
                    style={{
                        InkscapeFontSpecification:
                            "'SegoeUI-BoldItalic, Segoe UI, sans-serif, Bold Italic'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "small-caps",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                    }}
                >
                    {props.daystamp || "DAYSTAMP"}
                </tspan>
            </text>
            <text
                textRendering="optimizeLegibility"
                x={-414.697}
                y={1309.025}
                fontStyle="italic"
                fontWeight={700}
                fontSize={235.751}
                fontFamily="SegoeUI-BoldItalic,'Segoe UI','Open Sans',sans-serif"
                fill="#fff"
                transform="matrix(.19658 0 0 .2132 1132.467 15.689)"
            >
                <tspan
                    style={{
                        InkscapeFontSpecification:
                            "'SegoeUI-BoldItalic, Segoe UI, sans-serif, Bold Italic'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "small-caps",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        textAlign: "center",
                    }}
                    x={-414.697}
                    y={1309.025}
                    textAnchor="middle"
                >
                    {props.date || "DATE"}
                </tspan>
            </text>
            <text
                textRendering="optimizeLegibility"
                x={847.483}
                y={241.268}
                fontStyle="italic"
                fontSize={34.803}
                fontFamily="SegoeUI-Italic,'Segoe UI','Open Sans',sans-serif"
                fill="#fff"
                transform="matrix(.71586 0 0 .77641 551.942 137.559)"
            >
                <tspan
                    style={{
                        textAlign: "end",
                    }}
                    textAnchor="end"
                >
                    {props.prodno || "PRODNO"}
                </tspan>
            </text>
            <g fill="#fff">
                <text
                    textRendering="optimizeLegibility"
                    x={142.963}
                    y={1063.62}
                    fontSize={54.167}
                    fontFamily="SegoeUI,'Segoe UI','Open Sans',sans-serif"
                    transform="translate(16.162 -24.707) scale(1.0194)"
                >
                    {props.plaindaytext || "PLAINDAYTEXT"}
                </text>
                <text
                    textRendering="optimizeLegibility"
                    x={268.458}
                    y={1098.9}
                    fontSize={35.285}
                    fontFamily="SegoeUI,'Segoe UI','Open Sans',sans-serif"
                    transform="translate(16.162 -24.707) scale(1.0194)"
                />
                <text
                    textRendering="optimizeLegibility"
                    transform="translate(161.899 1058.56) scale(1.0194)"
                    fontSize={54.167}
                    fontFamily="SegoeUI-Bold,'Segoe UI','Open Sans',sans-serif"
                >
                    {(!!props.exercise && !!props.exercise.length) && (
                        props.exercise.map((temp, i) => {


                            var exerciseSVGText = []
                            var originalSecond = temp.second;
                            var newSecond = originalSecond % 60;
                            var newMinute = ~~(originalSecond / 60);
                            var originalMinute = newMinute;
                            newMinute = originalMinute % 60;
                            var newHour = ~~(originalMinute / 60);

                            var hourText = "hour";
                            var minuteText = "minute";
                            var secondText = "second";

                            if (temp.amount === 0 && temp.rounds > 0 && temp.amountround > 0) {
                                temp.amount = temp.rounds * temp.amountround;
                                if (temp.per !== "") {
                                    temp.amount = temp.amount * 2;
                                }
                            }


                            if (newHour > 0) {
                                minuteText = "min";
                                secondText = "sec";
                            }

                            if (newSecond > 0) {
                                hourText = "hr";
                            }

                            if (temp.amount > 0 && temp.second === 0) {

                                exerciseSVGText.push(<tspan fontWeight="bold">{temp.amount} {helpers.returnPlural(temp.amount, null, temp.type)}</tspan>);

                                if (temp.rounds > 0 && temp.amountround > 0) {
                                    exerciseSVGText.push(` (${temp.rounds} * ${temp.amountround}${(temp.per !== "" ? ` per ${temp.per}` : "")})`);
                                }

                            } else if (originalSecond > 0) {
                                exerciseSVGText.push(<tspan fontWeight="bold">{temp.amount} {helpers.returnPlural(temp.amount, null, temp.type)} of {((newHour !== 0) ? `${newHour} ${hourText}${(newHour > 1 ? "s" : "")} ` : "")}{((newMinute > 0) ? `${newMinute} ${minuteText}${(newMinute > 1 ? "s" : "")} ` : "")}{((newSecond > 0) ? `${newSecond} ${secondText}${(newSecond > 1 ? "s" : "")}` : "")}</tspan>);

                                // exerciseSVGText += <tspan fontWeight="bold">{(temp.type)} for {((newHour != 0) ? `${newHour} ${hourText}${(newHour > 1 ? "s" : "")} ` : "")}{((newMinute > 0) ? `${newMinute} ${minuteText}${(newMinute > 1 ? "s" : "")} ` : "")}{((newSecond > 0) ? `${newSecond} ${secondText}${(newSecond > 1 ? "s" : "")}` : "")}</tspan>


                            } else {
                                exerciseSVGText.push(<tspan fontWeight={"bold"}>{temp.type}</tspan>);
                            }
                            return (
                                <tspan key={i} xmlns={"http://www.w3.org/2000/svg"} x={0} dy={"1.2em"}>
                                    • {exerciseSVGText.map((e, i) => (<React.Fragment key={i}>{e}</React.Fragment>))}
                                </tspan>
                            )
                        })
                    )}
                    {restDay && (
                        <tspan xmlns={"http://www.w3.org/2000/svg"} x={0} dy={"1.2em"}>
                            • <React.Fragment>{"No activity :)"}</React.Fragment>
                        </tspan>
                    )}
                </text>
            </g>
            <text
                textRendering="optimizeLegibility"
                x={506.745}
                y={855.733}
                fontStyle="italic"
                fontWeight={700}
                fontSize={113.683}
                fontFamily="SegoeUI-BoldItalic,'Segoe UI','Open Sans',sans-serif"
                fill="#fff"
                transform="translate(71.6 -231.037) scale(1.11204)"
            >
                <tspan
                    style={{
                        textAlign: "center",
                    }}
                    textAnchor="middle"
                >
                    {props.weekno || "WEEKNO"}
                </tspan>
            </text>
            <text
                textRendering="optimizeLegibility"
                x={692.317}
                y={940.924}
                fontSize={114.545}
                fontFamily="SegoeUI,'Segoe UI','Open Sans',sans-serif"
                fill="#fff"
                transform="translate(-126.986 -116.568) scale(1.10366)"
            >
                <tspan
                    style={{
                        InkscapeFontSpecification:
                            "'SegoeUI, Segoe UI, Open Sans, sans-serif'",
                        textAlign: "center",
                    }}
                    fontWeight={props.boldday ? 700 : 400}
                    textAnchor="middle"
                >
                    {props.dayno || "DAYNO"}
                </tspan>
            </text>
            <text
                textRendering="optimizeLegibility"
                x={"50%"}
                y={540.063}
                fontSize={41.679}
                fontFamily="'Segoe UI','Open Sans'"
            >
                <tspan >
                    <tspan
                        style={{
                            InkscapeFontSpecification: "'SF Pro Text'",
                            textAlign: "center",
                        }}
                        fontWeight={400}
                        fontFamily="'Open Sans'"
                        textAnchor={"middle"}
                        fill="#fff"
                        fillOpacity={0.603}
                    >
                        {props.regimenname || "REGIMENNAME"} regimen
                    </tspan>
                </tspan>
            </text>
            <text
                textRendering="optimizeLegibility"
                x={508.189}
                y={1104.67}
                style={{
                    InkscapeFontSpecification:
                        "'SegoeUI, Segoe UI, Open Sans, sans-serif, Normal'",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "small-caps",
                    fontVariantNumeric: "normal",
                    fontVariantEastAsian: "normal",
                }}
                fontWeight={400}
                fontSize={41.667}
                fontFamily="SegoeUI,'Segoe UI','Open Sans',sans-serif"
                fill="#fff"
                transform="translate(77.973 -251.641) scale(1.10366)"
            >
                <tspan
                    style={{
                        InkscapeFontSpecification:
                            "'SegoeUI, Segoe UI, Open Sans, sans-serif, Normal'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "small-caps",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        textAlign: "center",
                    }}
                    textAnchor="middle"
                >
                    {props.daydate || "daydate"}
                </tspan>
            </text>
            <text
                textRendering="optimizeLegibility"
                x={1068.15}
                y={1271.4}
                fontSize={73.341}
                fontFamily="SegoeUI,'Segoe UI','Open Sans',sans-serif"
                fill="#fff"
                transform="matrix(.62702 0 0 .62702 -28.853 -21.485)"
            >
                <tspan
                    x={1068.15}
                    y={1271.4}
                    style={{
                        InkscapeFontSpecification:
                            "'SegoeUI, Segoe UI, Open Sans, sans-serif, Normal'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "small-caps",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        textAlign: "center",
                    }}
                    fontWeight={400}
                    textAnchor="middle"
                >
                    {props.weekdates || "weekdates"}
                </tspan>
            </text>
            <text
                textRendering="optimizeLegibility"
                x={-422.367}
                y={791.641}
                style={{
                    InkscapeFontSpecification:
                        "'SegoeUI-BoldItalic, Segoe UI, Open Sans, sans-serif, Bold Italic'",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "small-caps",
                    fontVariantNumeric: "normal",
                    fontVariantEastAsian: "normal",
                }}
                fontStyle="italic"
                fontWeight={700}
                fontSize={41.667}
                fontFamily="SegoeUI-BoldItalic,'Segoe UI','Open Sans',sans-serif"
                fill="#fff"
                transform="translate(1078.05 -218.624) scale(1.04095)"
            >
                <tspan
                    style={{
                        InkscapeFontSpecification:
                            "'SegoeUI-BoldItalic, Segoe UI, Open Sans, sans-serif Italic'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "small-caps",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        textAlign: "center",
                    }}
                    x={-422.367}
                    y={791.641}
                    fontWeight={400}
                    textAnchor="middle"
                >
                    {props.captiontext || "captiontext"}
                </tspan>
            </text>
            <defs>
                <linearGradient
                    id="baseFile_svg__a"
                    x1={0}
                    y1={0}
                    x2={0.828}
                    y2={0.023}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(63.324 968.449 -1349.813) scale(4168.46)"
                >
                    <stop offset={0} stopColor="COLOURONE" />
                    <stop offset={1} stopColor="COLOURTWO" />
                </linearGradient>
                <path
                    id="baseFile_svg__b"
                    d="M478.342 501.598h560.667v99.297H478.342z"
                />
            </defs>
            <text
                textRendering="optimizeLegibility"
                x={-5378.066}
                y={-4601.602}
                style={{
                    InkscapeFontSpecification:
                        "'SegoeUI-BoldItalic, Segoe UI, Open Sans, sans-serif, Bold Italic'",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "small-caps",
                    fontVariantNumeric: "normal",
                    fontVariantEastAsian: "normal",
                }}
                fontStyle="italic"
                fontWeight={700}
                fontSize={148.151}
                fontFamily="SegoeUI-BoldItalic,'Segoe UI','Open Sans',sans-serif"
                fill="#fff"
                strokeWidth={0.628}
                transform="matrix(.25263 0 0 .274 1469.143 3417.858)"
            >
                <tspan
                    x={-5378.066}
                    y={-4601.602}
                    style={{
                        InkscapeFontSpecification:
                            "'SegoeUI-BoldItalic, Segoe UI, Open Sans, sans-serif Italic'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "small-caps",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                    }}
                    fontWeight={400}
                >
                    {"https://regimen.social/"}
                </tspan>
            </text>
            <g>
                <text
                    textRendering="optimizeLegibility"
                    x={-860.175}
                    y={948.838}
                    style={{
                        lineHeight: 1,
                        InkscapeFontSpecification:
                            "'SegoeUI-BoldItalic, Segoe UI, Open Sans, sans-serif, Bold Italic'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "small-caps",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                    }}
                    fontStyle="italic"
                    fontWeight={700}
                    fontSize={193.952}
                    fontFamily="SegoeUI-BoldItalic,'Segoe UI','Open Sans',sans-serif"
                    fill="#fff"
                    strokeWidth={0.822}
                    transform="matrix(.18213 0 0 .19754 1316.947 1934.424)"
                >
                    <tspan
                        x={-860.175}
                        y={948.838}
                        style={{
                            InkscapeFontSpecification:
                                "'SegoeUI-BoldItalic, Segoe UI, Open Sans, sans-serif'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "small-caps",
                            fontVariantNumeric: "normal",
                            fontVariantEastAsian: "normal",
                            textAlign: "end",
                        }}
                        fontStyle="normal"
                        fontWeight={400}
                        textAnchor="end"
                    >
                        {"mmxxi"}
                    </tspan>
                    <tspan
                        x={-807.047}
                        y={1142.79}
                        style={{
                            InkscapeFontSpecification:
                                "'SegoeUI-BoldItalic, Segoe UI, Open Sans, sans-serif'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "small-caps",
                            fontVariantNumeric: "normal",
                            fontVariantEastAsian: "normal",
                            textAlign: "end",
                        }}
                        fontStyle="normal"
                        fontWeight={400}
                        textAnchor="end"
                    >
                        {"copyright regimensocial"}
                    </tspan>
                </text>
            </g>
            <g>
                <path
                    d="M1205.39 341.37H934.462L821.319 228.227H74.687V75.968H1205.39z"
                    fill="#fff"
                    fillOpacity={0.01}
                    stroke="#fff"
                    strokeWidth={2.13}
                    strokeOpacity={0.1}
                    transform="matrix(1.00148 0 0 1.00526 -.986 -1.622)"
                />
            </g>
        </svg>
    );
}

export default BaseImage;