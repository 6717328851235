// import logo from './logo.svg';
import React, { useContext } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import "./styles/main.scss";

import { UserContext } from "./contexts/UserContext";
import logo from "./images/logosingle.svg";
import LoggedIn from './centrals/LoggedIn';
import LoggedOut from './centrals/LoggedOut';

// import { helpers } from './main.js';




var App = () => {
	// static contextType = UserContext;

	// constructor(props) {
	//     super(props);
	// }


	var userContext = useContext(UserContext)
	var CentralEl = userContext.loggedIn ? LoggedIn : LoggedOut;


	return (
		<>
			<>

				<div id={"main"}>
					<img src={logo} alt={"regimensocial"} className={"bannerImage nS"} />
					

					<Routes>
						<Route
							path="/:pageAddress"
							element={CentralEl}
						/>
						<Route
							path="/:pageAddress/:firstParam"
							element={CentralEl}
						/>
						<Route
							path="/:pageAddress/:firstParam/:secondParam"
							element={CentralEl}
						/>
						<Route
							path="/:pageAddress/:firstParam/:secondParam/:thirdParam"
							element={CentralEl}
						/>
						<Route
							path="/:pageAddress/:firstParam/:secondParam/:thirdParam/:fourthParam"
							element={CentralEl}
						/>
						<Route
							path="/:pageAddress/:firstParam/:secondParam/:thirdParam/:fourthParam/:fifthParam"
							element={CentralEl}
						/>
						<Route
							path="/"
							element={<Navigate to="/home" />}
						/>

					</Routes>
				</div>
			</>




			<div id="movingBackground"></div>
		</>

	)
}

export default App;